export const CREDITS_DOMAIN = 'https://www.deemaze.com';

export const SPREADMINT_SOCIALS = {
  facebook: 'https://www.facebook.com/spreadmint',
  twitter: 'https://twitter.com/spread_mint',
  instagram: 'https://www.instagram.com/spreadmint/',
};

export const SPREADMINT_CONTACTS = {
  support: 'support@spreadmint.com',
};
