import React from 'react';

import PropTypes from 'prop-types';

import { Loader, TokenCard } from '@SharedComponents/';

import useMyTokens from '@Hooks/useMyTokens';

import useInfiniteScroll from '@Hooks/useInfiniteScroll';

const Tokens = ({ tokens, meta }) => {
  const { myTokens, hasNextPage, isLoadingTokens, loadMoreTokens } = useMyTokens(tokens, meta);

  const infiniteScrollRef = useInfiniteScroll(loadMoreTokens);

  return (
    <div className="grid g-col-12 panel panel-background">
      <hr className="d-block d-lg-none g-col-12" />
      <div className="d-flex g-col-12 d-block flex-column px-4 px-lg-5 mb-lg-0 panel panel-tokens">
        <div className="g-col-lg-8 pt-4 py-lg-5 px-2 px-lg-0 mb-3">
          <h4 className="avenir-500 f-18 f-xs-16 f-sm-16 text-uppercase small-caps">
            All
            <span className="color-primary-blue px-1">({meta.tokensCount})</span>
          </h4>
        </div>
        {myTokens.length > 0 ? (
          <div className="tokens-grid my-4">
            {myTokens.map((token) => (
              <TokenCard token={token} key={token.id} />
            ))}
          </div>
        ) : (
          <div className="my-5 pt-3 pb-5 text-center">
            <h5 className="avenir-400 color-gray-300 f-16">No tokens to display yet.</h5>
          </div>
        )}
        {hasNextPage && <Loader ref={infiniteScrollRef} isLoading={isLoadingTokens} />}
      </div>
    </div>
  );
};

Tokens.propTypes = {
  tokens: PropTypes.array,
  meta: PropTypes.shape({
    nextPage: PropTypes.bool,
    tokensCount: PropTypes.number,
  }).isRequired,
};

export default Tokens;
