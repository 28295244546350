import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { useMount } from 'react-use';

const MINIMUM_REF_SIZE = 80;

const Image = ({
  alt,
  round = false,
  fallbackText = 'Image not available',
  className,
  ...rest
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setIsValid] = useState(!!rest.src);
  const [fallbackTextScale, setFallbackTextScale] = useState(1);

  const imageContainerRef = useRef(null);

  const containerClasses = classNames(
    'image-container',
    {
      'blurred skeleton': !isLoaded && isValid,
      round,
      square: !round,
    },
    className
  );

  const scaleFallbackText = () => {
    if (!imageContainerRef) return;

    const containerHeight = imageContainerRef?.current?.clientHeight;
    const containerWidth = imageContainerRef?.current?.clientWidth;

    if (containerHeight < MINIMUM_REF_SIZE || containerWidth < MINIMUM_REF_SIZE)
      setFallbackTextScale(0.6);
  };

  useMount(scaleFallbackText);

  return (
    <div
      role="presentation"
      className={containerClasses}
      ref={imageContainerRef}
      style={{ width: rest.width, height: rest.height }}
      onClick={rest?.onClick}
    >
      {isValid ? (
        <img
          alt={alt}
          src={rest?.src}
          loading="lazy"
          draggable="false"
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsValid(false)}
        />
      ) : (
        <p
          className="fallback avenir-600 f-12 color-gray-200 text-center text-uppercase small-caps w-100"
          style={{
            transform: `scale(${fallbackTextScale})`,
          }}
        >
          {fallbackText}
        </p>
      )}
    </div>
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  round: PropTypes.bool,
  fallbackText: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
