import React from 'react';
import PropTypes from 'prop-types';

import { Button, Input } from '../../Shared';

import useNewsletter from '../../../hooks/useNewsletter';

const NewsletterSubscription = ({ handleModal }) => {
  const { email, setEmail, subscribe, isSubscribing, errorMessage } = useNewsletter({
    onSucess: handleModal,
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="d-flex flex-column align-items-center align-items-lg-start footer-col">
      <h6 className="avenir-500 f-14 f-xs-18 f-sm-18 f-md-18 text-uppercase small-caps">
        Newsletter
      </h6>
      <p className="avenir-400 f-12 f-xs-18 f-sm-18 f-md-18 mb-5 mb-lg-4">
        Keep up with your favorite artists and get the latest updates firsthand!
      </p>
      <div className="d-flex flex-column flex-xxl-row gap-4 gap-md-3 pb-3 w-100 align-items-center">
        <Input
          name="email"
          className="w-100"
          value={email}
          placeholder="E-mail"
          onChange={handleEmailChange}
          error={errorMessage}
        />
        <Button
          label="Subscribe"
          className="align-self-center align-self-lg-start w-xl-auto w-xxl-auto"
          onClick={subscribe}
          submitting={isSubscribing}
          secondary
        />
      </div>
    </div>
  );
};

NewsletterSubscription.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

export default NewsletterSubscription;
