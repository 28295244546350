import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextArea = forwardRef(
  ({ label = null, description = null, type = 'text', error = null, rows = 3, ...rest }, ref) => {
    const textAreaInputClasses = classNames(
      'textarea-input-group',
      {
        error,
      },
      rest.className
    );

    return (
      <div className={textAreaInputClasses}>
        <div className="input-text">
          <label>{label}</label>
          {description !== null && <p>{description}</p>}
        </div>
        <div className="input">
          <textarea className={error && 'error'} type={type} rows={rows} ref={ref} {...rest} />
          {error && <span className="material-icons input-icon error-icon">error_outline</span>}
        </div>
        {error && <label className="error">{error}</label>}
      </div>
    );
  }
);

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  error: PropTypes.string,
};

export default TextArea;
