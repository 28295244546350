import client from './client';

import { managerAppSettingsPath } from '@Utils/routes.js.erb';

const mapFormDataToParams = ({
  email,
  firstName,
  lastName,
  password,
  currentPassword,
  passwordConfirmation,
  avatarUrl,
  avatarChanged,
}) => {
  const params = {
    firstName,
    lastName,
    email,
    password,
    currentPassword,
    passwordConfirmation,
  };

  if (avatarChanged) params.avatar = { data: avatarUrl };

  return params;
};

const updateManagerSettings = async (id, payload) => {
  const params = mapFormDataToParams(payload);
  await client.patch(managerAppSettingsPath(), {
    manager: params,
  });
};

const deleteManagerAccount = async () => {
  await client.delete(managerAppSettingsPath());
};

export { updateManagerSettings, deleteManagerAccount };
