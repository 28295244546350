import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import { Input } from '..';

const NumericInput = forwardRef((props, ref) => {
  const replacePeriod = (value) => value.replace(',', '.');

  const replaceDuplicatedPeriods = (value) => {
    const commaIndex = value.indexOf('.');
    const val = value.substr(0, commaIndex + 1) + value.substr(commaIndex + 1).replace(/\./g, '');
    return val;
  };

  const cleanNumericInput = (value) => {
    let val = replacePeriod(value);
    val = replaceDuplicatedPeriods(val);
    return val;
  };

  const handleControlledKeyDown = (e) => {
    return ['e', 'E', '+', '-', '`', '´', '~'].includes(e.key) && e.preventDefault();
  };

  return (
    <Input
      ref={ref}
      {...props}
      type="text"
      inputMode="decimal"
      onChange={(e) => {
        const { value } = e.target;
        // eslint-disable-next-line no-param-reassign
        e.target.value = cleanNumericInput(value);
        return props.onChange(e);
      }}
      onKeyDown={handleControlledKeyDown}
    />
  );
});

NumericInput.propTypes = { onChange: PropTypes.func };

export default NumericInput;
