import React, { useState } from 'react';

import { Modal } from '@SharedComponents/';

import NewsletterSubscription from '@MainAppComponents/NewsletterSubscription';

import thankYou from '@Images/success.svg';

import useGridBreakpoints from '@Hooks/useGridBreakpoint';

import { currentYear } from '@Utils/date';

import SocialMedia from '@SharedComponents/SocialMedia';

import { CREDITS_DOMAIN, SPREADMINT_SOCIALS } from '@Lib/constants';

const INFO_PAGES = [
  {
    title: 'FAQs',
    link: 'faqs',
  },
  {
    title: 'Privacy Policy',
    link: 'privacy_policy',
  },
  {
    title: 'Terms and conditions',
    link: 'terms_and_conditions',
  },
];

const Footer = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const { belowDesktop } = useGridBreakpoints();

  const handleModalVisibility = () => {
    setModalVisibility((state) => !state);
  };

  return (
    <footer>
      <Modal visible={modalVisibility} onClose={handleModalVisibility} closeIcon>
        <img src={thankYou} alt="thank you illustration" />
        <div className="d-flex flex-column">
          <h3 className="archivo-600 color-black f-24 text-center">Thank you!</h3>
          <p className="avenir-400 color-dark f-16 text-center mt-4">
            You are now subscribed to our newsletter and will receive all updates about our website.
          </p>
        </div>
      </Modal>
      <div className="grid footer-grid">
        <div className="d-flex flex-column footer-newsletter">
          <NewsletterSubscription handleModal={handleModalVisibility} />
        </div>
        <div className="d-flex flex-column footer-social">
          <h6 className="avenir-500 f-14 f-xs-18 f-sm-18 f-md-18 text-uppercase small-caps">
            Join Us On Social Media
          </h6>
          <SocialMedia
            socials={SPREADMINT_SOCIALS}
            className="footer-social-icons d-flex color-white"
            size={belowDesktop ? 45 : 27}
          />
        </div>
        <div className="text-center text-lg-start footer-about">
          <h6 className="avenir-500 f-14 f-xs-18 f-sm-18 f-md-18 text-uppercase small-caps">
            About Us
          </h6>
          <div className="d-block d-lg-none">
            <p className="avenir-400 f-12 f-xs-18 f-sm-18 f-md-18">
              Spreadmint is a project that seeks to revolutionize the NFT market and the relations
              between artists and their fans.
            </p>
            <p className="avenir-400 f-12 f-xs-18 f-sm-18 f-md-18">
              Through our NFT’s you can be even closer to your favorite artists while investing
              money and discovering new ways of commercializing art.
            </p>
          </div>
          <div className="d-none d-lg-block">
            <p className="avenir-400 f-12 f-xs-18 f-sm-18 f-md-18">
              Spreadmint is a project that seeks to revolutionize the NFT market and the relations
              between artists and their fans. Through our NFT’s you can be even closer to your
              favorite artists while investing money and discovering new ways of commercializing
              art.
            </p>
          </div>
        </div>
        <div className="text-center text-lg-start footer-navigate">
          <h6 className="avenir-500 f-14 f-xs-18 f-sm-18 f-md-18 text-uppercase small-caps">
            Information
          </h6>
          <ul>
            {INFO_PAGES.map(({ title, link }) => (
              <li key={`navigation_${title}`}>
                <a className="avenir-400 f-12 f-xs-18 f-sm-18 f-md-18" href={`${link}`}>
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="text-center footer-copyright w-100">
          <a
            className="avenir-400 avenir-lg-400 avenir-xl-400 avenir-xxl-400 f-12 f-xs-18 f-sm-18 f-md-18 small-caps text-uppercase"
            href={CREDITS_DOMAIN}
          >
            &copy;{currentYear()} Deemaze Software
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
