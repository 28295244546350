export const formatNumber = (number) => {
  if (!number) return '—';
  return new Intl.NumberFormat('en-US').format(number);
};

export const formatDollar = (number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

export const pluralize = (count, string) => {
  if (count === 1) return string;
  return `${string}s`;
};

export const capitalizeStringFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();
export default capitalizeStringFirstLetter;

export const isValidNumber = (number) => {
  if (
    Number.isNaN(number) ||
    number === undefined ||
    number.length === 0 ||
    number < 0 ||
    number === ''
  )
    return false;
  return true;
};
