import React from 'react';

import PropTypes from 'prop-types';

import { Image } from '..';

import BidCardLayout from './BidLayout';

import FixedCardLayout from './FixedLayout';

import { FavoriteIcon } from '@MainAppComponents/';

import { mainAppArtistPath, mainAppCollectionTokenPath } from '@Routes';

import { TokenShape } from '@Utils/propsShape';

const TokenCard = ({ token, favoriteAction = true, redirect = true, favoriteCallback }) => {
  const {
    id,
    artist,
    tokenId,
    imageUrl,
    favorite,
    listing,
    topOfferAmount,
    topBidAmount,
    collection,
    tier,
  } = token;

  const renderLayout = (listingType) => {
    switch (listingType) {
      case 'FixedPrice':
        return <FixedCardLayout {...{ listing, topOfferAmount }} />;
      case 'HighestBid':
        return <BidCardLayout {...{ listing, topBidAmount }} />;
      default:
        return <FixedCardLayout {...{ listing, topOfferAmount }} />;
    }
  };

  let tokenImageAlterText = `${String(artist.name).toUpperCase()} token ${id}`;
  if (collection?.title) tokenImageAlterText += `from ${collection.title} Collection`;
  if (tier?.title) tokenImageAlterText += `- ${tier.title} tier`;

  return (
    <div className="panel token" key={id}>
      <div className="d-flex flex-column w-100">
        <h6 className="avenir-600 f-14 color-primary-blue d-inline text-uppercase small-caps mb-2">
          By{' '}
          {redirect ? (
            <a href={mainAppArtistPath(artist.slug)} className="cursor-pointer">
              {artist.name}
            </a>
          ) : (
            artist.name
          )}
        </h6>
        {redirect ? (
          <a
            href={mainAppCollectionTokenPath(collection.blockchainAddress, tokenId)}
            className="cursor-pointer"
          >
            <h5 className="avenir-600 f-16 d-inline">Token #{tokenId}</h5>
          </a>
        ) : (
          <h5 className="avenir-600 f-16 d-inline">Token #{tokenId}</h5>
        )}

        {favoriteAction && (
          <FavoriteIcon
            className="color-black"
            favorite={favorite}
            tokenId={id}
            favoriteCallback={favoriteCallback}
          />
        )}
      </div>

      {redirect ? (
        <>
          <a href={mainAppArtistPath(artist.slug)} className="cursor-pointer">
            <Image src={imageUrl} alt={tokenImageAlterText} className="token-preview" />
          </a>
          <a
            href={mainAppCollectionTokenPath(collection.blockchainAddress, tokenId)}
            className="cursor-pointer w-100"
          >
            {renderLayout(listing?.type)}
          </a>
        </>
      ) : (
        <>
          <Image src={imageUrl} alt={tokenImageAlterText} className="token-preview" />
          <div className="w-100">{renderLayout(listing?.type)}</div>
        </>
      )}
    </div>
  );
};

TokenCard.propTypes = {
  token: TokenShape,
  favoriteAction: PropTypes.bool,
  redirect: PropTypes.bool,
  favoriteCallback: PropTypes.func,
};

export default TokenCard;
