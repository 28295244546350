import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '../../Shared';

import { MARKETPLACE_SORT_BY } from '../../../lib/constants';

const TokensSort = ({ sortBy }) => {
  const [selectedSort, setSelectedSort] = useState(
    MARKETPLACE_SORT_BY.select_recently_listed.value
  );
  const handleSortChange = ({ sortTokens }) => {
    setSelectedSort(sortTokens);
    sortBy(sortTokens);
  };

  return (
    <>
      <h6 className="avenir-500 f-16 text-uppercase small-caps mb-4 mb-lg-2">Sort By</h6>
      <div className="dropdown">
        <Dropdown
          name="sortTokens"
          data={Object.values(MARKETPLACE_SORT_BY)}
          selected={selectedSort}
          onClick={handleSortChange}
        />
      </div>
    </>
  );
};

TokensSort.propTypes = {
  sortBy: PropTypes.func,
};

export default TokensSort;
