import React, { useState, useRef, forwardRef } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { useClickAway } from 'react-use';

const ControlledDropdown = forwardRef(
  (
    {
      name,
      label = null,
      description = null,
      placeholder = 'Select option',
      data,
      value,
      error = null,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownArea = useRef(null);

    const handleToggleControlledDropdown = () => setIsOpen((state) => !state);

    const handleCloseControlledDropdown = () => setIsOpen(false);

    const handleSelectItem = (event) => {
      event.preventDefault();
      if (rest.onChange) rest.onChange(event);
      handleCloseControlledDropdown();
    };

    const displaySelected = () =>
      Object.values(data).map(({ value: dataVal, title }) => dataVal === value && title);

    const dropdownClasses = classNames('dropdown-box', {
      'dropdown-open': isOpen,
      error,
    });

    const dropdownSelectorClasses = classNames('dropdown-selector', {
      error,
    });

    useClickAway(dropdownArea, handleCloseControlledDropdown);

    return (
      <div role="presentation" className={dropdownClasses} ref={dropdownArea}>
        {(label || description) && (
          <div className="input-text">
            {label && <label>{label}</label>}
            {description && <p>{description}</p>}
          </div>
        )}
        <div
          role="presentation"
          className={dropdownSelectorClasses}
          onClick={handleToggleControlledDropdown}
        >
          {!value ? (
            <label htmlFor="default" className="avenir-400 truncate max-line-1">
              {placeholder}
            </label>
          ) : (
            <label className="selected avenir-400 truncate max-line-1">{displaySelected()}</label>
          )}
          <span className="material-icons">keyboard_arrow_down</span>
        </div>
        <div className="dropdown-list">
          {data.map((el) => {
            return (
              <button
                className="avenir-400 truncate max-line-1"
                value={el.value}
                key={`dropdown_${el.value}`}
                onClick={handleSelectItem}
              >
                {el.title}
              </button>
            );
          })}
        </div>
        {error && <label className="error">{error}</label>}
        <input ref={ref} {...rest} style={{ display: 'none' }} />
      </div>
    );
  }
);

ControlledDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  error: PropTypes.string,
};

export default ControlledDropdown;
