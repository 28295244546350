/* eslint-disable import/prefer-default-export */
export const MARKEPLACE_SEARCH_RESULTS_SLIDER_SETTINGS = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  width: 'auto',
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        width: '30rem',
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
