import React from 'react';

import { SVGIconsShape } from '@Utils/propsShape';

const FacebookIcon = ({ width, height }) => (
  <svg {...{ width, height }} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.5134 0.869141H2.48362C1.11289 0.869141 0 1.97982 0 3.3528V43.3833C0 44.7562 1.11289 45.8684 2.48362 45.8684H24.0345V28.442H18.1707V21.6498H24.0345V16.641C24.0345 10.8297 27.5828 7.66363 32.7679 7.66363C35.2537 7.66363 37.3854 7.84961 38.007 7.93038V14.0047L34.4105 14.0062C31.5913 14.0062 31.0474 15.3465 31.0474 17.3123V21.6475H37.7737L36.8942 28.4391H31.0467V45.8662H42.5127C43.8849 45.8662 44.9985 44.7525 44.9985 43.3833V3.35132C44.9978 1.97982 43.8856 0.869141 42.5134 0.869141Z"
      fill="currentColor"
    />
  </svg>
);

FacebookIcon.propTypes = SVGIconsShape.isRequired;

export default FacebookIcon;
