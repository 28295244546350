import React from 'react';

import PropTypes from 'prop-types';

import Image from '@SharedComponents/Image';

const NUM_DIFFERENT_CARDS = 3;
const TOTAL_ARTIST_CARDS = 40;

const BackgroundCardsGrid = ({ columnSize = 4 }) => {
  const perColumn = Math.floor(TOTAL_ARTIST_CARDS / columnSize);

  const tokens = Array.from(Array(TOTAL_ARTIST_CARDS).keys()).map((idx) => ({
    id: idx + 1,
    url: require(`assets/images/silhouette_${(idx % NUM_DIFFERENT_CARDS) + 1}.png`),
  }));

  const createGrid = () => {
    const grid = tokens.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / perColumn);
      // eslint-disable-next-line no-param-reassign
      if (!acc[chunkIndex]) acc[chunkIndex] = [];
      acc[chunkIndex].push(item);
      if (index < TOTAL_ARTIST_CARDS) return acc;
      return [];
    }, []);

    // evaluate if the reduced `g` contains more columns than the passed columnSize prop

    if (grid.length > columnSize) {
      // if so, select the last item from the array and spread the content across the other columns
      const excedent = grid.slice(-1).pop();
      excedent.forEach((element, i) => {
        grid[i].push(element);
      });
      // delete the excedent column
      grid.pop();
    }
    return grid;
  };

  return (
    <div className="background-cards-bg">
      <div className="background-cards-container">
        {createGrid().map((column, colIndex) => {
          return (
            <div
              className={`background-card-col background-card-col-${colIndex + 1}`}
              key={`col_${colIndex}`}
            >
              {column.map((token) => (
                <Image
                  key={`artist_card_${token.id}`}
                  src={token.url}
                  className="artist-card"
                  alt="Artist Card"
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

BackgroundCardsGrid.propTypes = {
  columnSize: PropTypes.number.isRequired,
};

export default BackgroundCardsGrid;
