import React from 'react';

import { SVGIconsShape } from '@Utils/propsShape';

const WebsiteIcon = ({ width, height }) => (
  <svg {...{ width, height }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 46" fill="none">
    <rect y="1" width="45" height="45" rx="2" fill="currentColor" />
    <path
      d="M28.668 26.6C28.772 25.742 28.85 24.884 28.85 24C28.85 23.116 28.772 22.258 28.668 21.4H33.062C33.27 22.232 33.4 23.103 33.4 24C33.4 24.897 33.27 25.768 33.062 26.6H28.668ZM26.367 33.828C27.147 32.385 27.745 30.825 28.161 29.2H31.996C30.7365 31.3688 28.7383 33.0116 26.367 33.828V33.828ZM26.042 26.6H19.958C19.828 25.742 19.75 24.884 19.75 24C19.75 23.116 19.828 22.245 19.958 21.4H26.042C26.159 22.245 26.25 23.116 26.25 24C26.25 24.884 26.159 25.742 26.042 26.6ZM23 34.348C21.921 32.788 21.05 31.059 20.517 29.2H25.483C24.95 31.059 24.079 32.788 23 34.348ZM17.8 18.8H14.004C15.2505 16.6254 17.2472 14.9799 19.62 14.172C18.84 15.615 18.255 17.175 17.8 18.8ZM14.004 29.2H17.8C18.255 30.825 18.84 32.385 19.62 33.828C17.2522 33.0112 15.2583 31.368 14.004 29.2V29.2ZM12.938 26.6C12.73 25.768 12.6 24.897 12.6 24C12.6 23.103 12.73 22.232 12.938 21.4H17.332C17.228 22.258 17.15 23.116 17.15 24C17.15 24.884 17.228 25.742 17.332 26.6H12.938ZM23 13.639C24.079 15.199 24.95 16.941 25.483 18.8H20.517C21.05 16.941 21.921 15.199 23 13.639V13.639ZM31.996 18.8H28.161C27.7541 17.1899 27.1516 15.6357 26.367 14.172C28.759 14.991 30.748 16.642 31.996 18.8ZM23 11C15.811 11 10 16.85 10 24C10 27.4478 11.3696 30.7544 13.8076 33.1924C15.0148 34.3995 16.4479 35.3571 18.0251 36.0104C19.6023 36.6637 21.2928 37 23 37C26.4478 37 29.7544 35.6304 32.1924 33.1924C34.6304 30.7544 36 27.4478 36 24C36 22.2928 35.6637 20.6023 35.0104 19.0251C34.3571 17.4479 33.3995 16.0148 32.1924 14.8076C30.9852 13.6005 29.5521 12.6429 27.9749 11.9896C26.3977 11.3363 24.7072 11 23 11V11Z"
      fill="white"
    />
  </svg>
);

WebsiteIcon.propTypes = SVGIconsShape.isRequired;

export default WebsiteIcon;
