import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

const Loader = forwardRef(({ isLoading }, loaderRef) => {
  const spans = new Array(8).fill(1);

  const renderLoadMoreText = () => (
    <h4 className="avenir-400 f-12 color-primary-blue">Loading more</h4>
  );

  return (
    <div className="d-flex flex-column align-items-center my-5 gap-5">
      <div className="preloader">
        {spans.map((_el, i) => (
          <span key={i}></span>
        ))}
      </div>
      {!isLoading ? <span ref={loaderRef}>{renderLoadMoreText()}</span> : renderLoadMoreText()}
    </div>
  );
});

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Loader;
