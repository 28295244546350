import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { useSelector } from 'react-redux';

import { useClickAway, useLockBodyScroll } from 'react-use';

import { Avatar, Button, Logo } from '..';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

import { SearchBar } from '@MainAppComponents/';

import navigation from '@Utils/navigation';

import {
  mainAppSignInPath,
  newManagerSessionPath,
  mainAppFeedPath,
  mainAppRootPath,
} from '@Utils/routes.js.erb';

import { getUser } from '@Store/slices/currentUser';

import { getManager } from '@Store/slices/currentManager';

const Navbar = ({
  menuOptions,
  userOptions,
  search = true,
  avatarClickRedirect,
  managerNav = false,
}) => {
  const { user } = useSelector(getUser);

  const { manager } = useSelector(getManager);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [openSearch, setOpenSearch] = useState(false);

  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const { belowDesktop } = useGridBreakpoint();

  const navArea = useRef();

  const path = window.location.pathname.split('/');
  const currentPage = `/${path[path.length - 1]}`;

  const handleToggleMenu = () => {
    setMenuOpen((state) => !state);
  };

  const goToAuthentication = () => navigation(mainAppSignInPath());

  const navigateApp = (page) => {
    navigation(page);
    if (isDropdownMenuOpen) setDropdownMenuOpen(false);
  };

  const navClasses = classNames('d-flex align-items-center', {
    'open-search': openSearch,
  });

  const hamburguerClasses = classNames('hamburguer-menu', {
    'hamburguer-menu-open': isMenuOpen,
  });

  const menuClasses = classNames('menu-options', {
    'menu-options-open': isMenuOpen,
  });

  const searchClasses = classNames('d-flex d-lg-none', {
    'd-none': !isMenuOpen,
  });

  const dropdownMenuClasses = classNames('navbar-dropdown', {
    open: isDropdownMenuOpen,
  });

  const userDetailsClasses = classNames({
    'user-details': user,
    'item-active': currentPage === 'feed',
    'auth-button': !user,
  });

  const handleAvatarClick = () => navigateApp(avatarClickRedirect);

  const hasMenuOptions = menuOptions !== undefined && menuOptions.length > 0;
  const hasUserOptions = userOptions !== undefined && userOptions.length > 0;

  const userLoggedMainApp = user ? mainAppFeedPath() : mainAppRootPath();
  const logoLink = managerNav ? newManagerSessionPath() : userLoggedMainApp;
  const username = managerNav ? manager?.firstName : user?.username;
  const avatar = managerNav ? manager?.avatarUrl : user?.avatarUrl;

  useLockBodyScroll(openSearch);

  useClickAway(navArea, () => {
    if (!openSearch) setMenuOpen(false);
  });

  return (
    <nav className={navClasses} ref={navArea}>
      <div className="grid">
        <div className="menu-column-1 g-col-6 g-col-sm-10 g-col-lg-5 d-flex align-items-center">
          <div className="grid">
            <div className="g-col-12 g-col-sm-4 g-col-lg-5 d-flex align-items-center">
              <a href={logoLink} className="d-flex cursor-pointer">
                <Logo />
              </a>
            </div>
            {search && (
              <div className="g-col-6 g-col-lg-7 d-flex align-items-center align-self-center d-none d-md-block">
                <SearchBar placeholder="Search" dark />
              </div>
            )}
          </div>
        </div>
        <div className="menu-column-2 g-col-6 g-col-sm-2 g-col-lg-7 d-flex align-items-center">
          {search && (
            <div className={searchClasses}>
              <SearchBar placeholder="Search" setOpen={setOpenSearch} open={openSearch} dark min />
            </div>
          )}
          {hasMenuOptions && (
            <div role="presentation" className={hamburguerClasses} onClick={handleToggleMenu}>
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
          )}
          <ul role="presentation" className={menuClasses}>
            {user ? (
              <li
                role="presentation"
                className={classNames('user-details', {
                  'item-active': isDropdownMenuOpen,
                })}
                onMouseEnter={() => setDropdownMenuOpen(true)}
                onMouseLeave={() => setDropdownMenuOpen(false)}
              >
                <span className="archivo-400 archivo-sm-500 archivo-xs-500 f-16 f-xs-24 f-sm-24">
                  {username}
                </span>
                <Avatar
                  src={avatar}
                  className="avatar-5"
                  alt="avatar_menu"
                  onClick={handleAvatarClick}
                />
                {hasUserOptions && !belowDesktop && (
                  <div role="presentation" className={dropdownMenuClasses}>
                    <div role="presentation" className="navbar-dropdown-list">
                      <ul className="w-100 d-flex flex-column align-items-end">
                        {userOptions.map(({ title, link }, index) => (
                          <li
                            role="presentation"
                            className="justify-content-end"
                            onClick={() => navigateApp(link)}
                            key={`user-ops_${index}`}
                          >
                            <p className="avenir-500 f-16 color-gray-200">{title}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            ) : (
              <li className={userDetailsClasses}>
                <Button label="Authenticate" onClick={goToAuthentication} />
              </li>
            )}
            {hasMenuOptions &&
              menuOptions.map(({ title, link }, index) => {
                const listItems = classNames({
                  'item-active': link === currentPage,
                });

                return (
                  <li
                    role="presentation"
                    key={index}
                    className={listItems}
                    onClick={() => navigateApp(link)}
                  >
                    <span className="archivo-400 archivo-sm-500 archivo-xs-500 f-16 f-xs-24 f-sm-24">
                      {title}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  avatarClickRedirect: PropTypes.string.isRequired,
  search: PropTypes.bool,
  menuOptions: PropTypes.array,
  userOptions: PropTypes.array,
  managerNav: PropTypes.bool,
};

export default Navbar;
