import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useDebounce, useFirstMountState, useUpdateEffect } from 'react-use';

import Skeleton from 'react-loading-skeleton';

import { Dropdown, Loader, Post } from '..';

import useInfiniteScroll from '@Hooks/useInfiniteScroll';

import useScrollItemsPagination from '@Hooks/useScrollPagination';

import { FILTERS_DEBOUNCE_VALUE, VISIBILITY_FILTERS } from '@Lib/constants';

import { PostShape, MetaShape } from '@Utils/propsShape';

const SKELETONS_POSTS = new Array(3).fill(1);

const Posts = ({ initialPosts, initialMeta, title = 'Posts', request, filters = true }) => {
  const [selectedFilter, setSelectedFilter] = useState(VISIBILITY_FILTERS.all_posts.value);
  const [isChangingFilter, setChangingFilter] = useState(false);

  const firstMount = useFirstMountState();

  const {
    items: posts,
    isLoading: isLoadingPosts,
    loadMoreItems: loadMorePosts,
    hasNextPage: hasMorePosts,
    resetItems: resetPosts,
  } = useScrollItemsPagination({
    initialItems: initialPosts,
    initialMeta,
    req: request,
    params: filters ? { filter: selectedFilter } : {},
    keyExtractor: 'posts',
    onMount: false,
    onSuccess: () => {
      if (filters) setChangingFilter(false);
    },
  });

  const infiniteScrollRef = useInfiniteScroll(loadMorePosts);

  const refreshPosts = () => !firstMount && resetPosts();

  useUpdateEffect(() => setChangingFilter(true), [selectedFilter]);

  useDebounce(() => refreshPosts(), FILTERS_DEBOUNCE_VALUE, [selectedFilter]);

  const renderPosts = () => {
    if (isChangingFilter || (isLoadingPosts && posts.length === 0)) {
      return (
        <div className="d-flex flex-column gap-5 w-100">
          {SKELETONS_POSTS.map((_, index) => (
            <div className="d-flex flex-column gap-4" key={`post_skeleton_${index}`}>
              <Skeleton
                width="20rem"
                height="3.4rem"
                highlightColor="var(--white-100)"
                baseColor="var(--white-900)"
                className="rounded-8"
              />
              <div className="d-flex flex-row gap-4 justify-content-between w-full">
                <div className="d-flex flex-row gap-4">
                  <Skeleton
                    width="4rem"
                    height="4rem"
                    highlightColor="var(--white-100)"
                    baseColor="var(--white-900)"
                    style={{ borderRadius: '100%' }}
                  />
                  <div className="d-flex flex-column gap-2">
                    <Skeleton
                      width="8rem"
                      height="1.6rem"
                      highlightColor="var(--white-100)"
                      baseColor="var(--white-900)"
                      className="rounded-5"
                    />
                    <Skeleton
                      width="21rem"
                      height="1.6rem"
                      highlightColor="var(--white-100)"
                      baseColor="var(--white-900)"
                      className="rounded-5"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Skeleton
                    width="10rem"
                    height="1.6rem"
                    highlightColor="var(--white-100)"
                    baseColor="var(--white-900)"
                    className="rounded-5"
                  />
                </div>
              </div>
              <Skeleton
                width="100%"
                height="70rem"
                highlightColor="var(--white-100)"
                baseColor="var(--white-900)"
                className="rounded-15"
              />
            </div>
          ))}
        </div>
      );
    }

    return posts.length > 0 ? (
      <div className="d-flex flex-column gap-5">
        {posts.map((post) => (
          <Post post={post} key={post.id} />
        ))}
        {hasMorePosts ? (
          <div className="p-5">
            <Loader ref={infiniteScrollRef} isLoading={isLoadingPosts} />
          </div>
        ) : (
          <div className="my-5 pt-3 h-100 d-flex align-items-center justify-content-center">
            <h4 className="avenir-400 f-16 color-gray-300">No more results</h4>
          </div>
        )}
      </div>
    ) : (
      <div className="my-5 pt-3 h-100 d-flex align-items-center justify-content-center">
        <h4 className="avenir-400 f-16 color-gray-300">No posts to display yet.</h4>
      </div>
    );
  };

  const renderFilters = () => (
    <div className="g-col-12 g-col-lg-5">
      <h4 className="avenir-500 f-16 mb-3 text-uppercase small-caps">Filter By</h4>
      <Dropdown
        name="filter"
        data={Object.values(VISIBILITY_FILTERS)}
        selected={selectedFilter}
        onClick={({ filter }) => setSelectedFilter(filter)}
      />
    </div>
  );

  return (
    <div className="grid g-col-12 panel panel-feed">
      {filters && <div className="d-lg-none panel panel-filter">{renderFilters()}</div>}
      <div className="d-none d-lg-grid pt-5 ps-5 pe-5 panel panel-filter">
        <div className="g-col-lg-7">
          <h6 className="avenir-500 f-18 text-uppercase small-caps">{title}</h6>
        </div>
        {filters && renderFilters()}
      </div>
      <div className="d-flex g-col-12 flex-column px-5 mb-5 mb-lg-0 panel panel-posts">
        <h6 className="d-lg-none avenir-500 f-18 my-5 text-uppercase small-caps">{title}</h6>
        {renderPosts()}
      </div>
    </div>
  );
};

Posts.propTypes = {
  initialPosts: PropTypes.arrayOf(PostShape),
  initialMeta: MetaShape,
  title: PropTypes.string,
  filters: PropTypes.bool,
  request: PropTypes.func,
};

export default Posts;
