import { useState } from 'react';

import createNewsletterSubscriberRequest from '../api/newsletter_subscriber';

const useNewsletter = ({ onSucess }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isSubscribing, setIsSubscribing] = useState(false);

  const subscribe = async () => {
    setIsSubscribing(true);
    setErrorMessage(null);

    try {
      await createNewsletterSubscriberRequest(email);
      setIsSubscribing(false);
      setEmail('');
      if (onSucess) onSucess();
    } catch ({ newsletterSubscriber }) {
      setErrorMessage(newsletterSubscriber.errors.email);
      setIsSubscribing(false);
    }
  };

  return {
    email,
    setEmail,
    subscribe,
    isSubscribing,
    errorMessage,
  };
};

export default useNewsletter;
