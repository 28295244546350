export const MARKETPLACE_FILTER_SECTIONS = {
  ARTISTS: 'artists',
  TIERS: 'tiers',
  PROPERTIES: 'properties',
};

export const MARKETPLACE_FILTER_ACTIONS = {
  FILTER_BY: 'filtering_by',
  RESET_SECTION: 'reset_section',
};
