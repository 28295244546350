import React, { useMemo, useRef } from 'react';

import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';

import { Icon } from '..';

/**
 *
 * @options flatpicker instance options: https://flatpickr.js.org/examples/
 */

const defaultOptions = {
  position: 'above',
};

const defaultDateOptions = {
  ...defaultOptions,
  minDate: 'today',
  dateFormat: 'd-m-Y',
};

const defaultTimeOptions = {
  ...defaultOptions,
  minTime: new Date().getTime(),
};

const DatePicker = ({ value, time, onChange: setValue, options }) => {
  const fp = useRef(null);

  const onDateChange = (date) => {
    const newDate = new Date(date);
    const currentDate = new Date(value);
    newDate.setHours(currentDate.getHours(), currentDate.getMinutes());
    setValue(newDate);
  };

  const onTimeChange = (date) => setValue(date);

  const onChange = ([date]) => {
    if (time) return onTimeChange(date);
    return onDateChange(date);
  };

  const instanceOptions = useMemo(() => {
    if (time) return { ...defaultTimeOptions, ...options };
    return {
      ...defaultDateOptions,
      ...options,
    };
  }, [options, time]);

  return (
    <div className="date-picker-input">
      <Flatpickr
        ref={fp}
        data-enable-time={time}
        data-no-calendar={time}
        onChange={onChange}
        value={value}
        options={instanceOptions}
      />
      <Icon
        name="keyboard_arrow_down"
        className="color-primary-blue"
        onClick={() => {
          if (!fp?.current?.flatpickr) return;
          fp.current.flatpickr.open();
        }}
      />
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  time: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.shape({}),
};

export default DatePicker;
