import React, { createElement } from 'react';

import PropTypes from 'prop-types';

import { FacebookIcon, InstagramIcon, TwitterIcon, WebsiteIcon } from '..';

import { SocialMediasShape } from '@Utils/propsShape';

const SocialMedia = ({ socials, className = 'd-flex', size = 21 }) => {
  const links = [];

  if (socials?.facebook) {
    links.push({ url: socials.facebook, icon: FacebookIcon });
  }

  if (socials?.twitter) {
    links.push({ url: socials.twitter, icon: TwitterIcon });
  }

  if (socials?.instagram) {
    links.push({ url: socials.instagram, icon: InstagramIcon });
  }

  if (socials?.website) {
    links.push({ url: socials.website, icon: WebsiteIcon });
  }

  return (
    <div className={className}>
      {links.map((social, i) => (
        <a
          style={{ color: 'unset' }}
          href={social.url}
          key={`social_${i}`}
          target="_blank"
          rel="noreferrer"
        >
          {createElement(social.icon, { width: size, height: size })}
        </a>
      ))}
    </div>
  );
};

SocialMedia.propTypes = {
  socials: SocialMediasShape.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};

export default SocialMedia;
