import { Ethereum, Sepolia } from '@thirdweb-dev/chains';

import { MAINNET_ADDRESSES, TESTNET_ADDRESSES } from '@Lib/constants';

export const getChain = (env) => (env === 'production' ? Ethereum : Sepolia);

export const getChainNetworkSlug = (env) => getChain(env).slug;

export const getContractTokenAddresses = (env) => {
  if (env === 'production') {
    return MAINNET_ADDRESSES;
  }
  return TESTNET_ADDRESSES;
};
