import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import Navbar from '@SharedComponents/Navbar';

import {
  mainAppMarketplacePath,
  mainAppFeedPath,
  mainAppMyTokensPath,
  mainAppFavoriteTokensPath,
  mainAppOffersPath,
  mainAppSettingsPath,
  mainAppSignOutPath,
} from '@Routes';

import { setUser } from '@Store/slices/currentUser';

const MENU_OPTIONS = [
  {
    title: 'Marketplace',
    link: mainAppMarketplacePath(),
  },
  {
    title: 'About Us',
    link: '#about-us',
  },
  {
    title: 'Create collection',
    link: '#create-collection',
  },
];

const USER_OPTIONS = [
  {
    title: 'Feed',
    link: mainAppFeedPath(),
  },
  {
    title: 'My Tokens',
    link: mainAppMyTokensPath(),
  },
  {
    title: 'Favorites',
    link: mainAppFavoriteTokensPath(),
  },
  {
    title: 'Offers',
    link: mainAppOffersPath(),
  },
  {
    title: 'Settings',
    link: mainAppSettingsPath(),
  },
  {
    title: 'Log out',
    link: mainAppSignOutPath(),
  },
];

const MainNavbar = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setUser({
        user,
      })
    );
  });

  return (
    <Navbar
      menuOptions={MENU_OPTIONS}
      userOptions={USER_OPTIONS}
      avatarClickRedirect={mainAppFeedPath()}
    />
  );
};

MainNavbar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
};

export default MainNavbar;
