import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

const CollapsePanel = ({
  id,
  open,
  openCallback,
  children,
  title,
  length,
  preventToogleMobile = true,
  topContent,
  bottomContent,
}) => {
  const [controlledOpen, setControlledOpen] = useState(open ?? false);

  const { belowDesktop } = useGridBreakpoint();

  const shouldToggle = !preventToogleMobile || belowDesktop;

  const handleCollapsePanel = () => {
    if (shouldToggle) setControlledOpen((state) => !state);
  };

  const panelClasses = classNames('collapse-panel collapse-body px-4', {
    collapsed: controlledOpen,
  });

  const bottomContentClasses = classNames('collapse-panel', {
    collapsed: controlledOpen,
  });

  useEffect(() => {
    if (openCallback) openCallback(controlledOpen);
  }, [controlledOpen, openCallback]);

  return (
    <>
      {topContent ? (
        topContent(handleCollapsePanel)
      ) : (
        <div className="d-flex d-lg-none justify-content-between collapse-panel-header">
          <h4 className="avenir-500 f-18 f-xs-16 f-sm-16 my-0 my-lg-5 text-uppercase small-caps">
            {title}
            {length > 0 && <span className="color-primary-blue px-1">({length})</span>}
          </h4>
          <div role="presentation" onClick={handleCollapsePanel}>
            <span className="avenir-500 f-16 color-primary-blue text-uppercase small-caps cursor-pointer">
              {controlledOpen ? 'Show' : 'Hide'}
            </span>
          </div>
        </div>
      )}
      <div id={id} className={panelClasses}>
        {children}
      </div>
      {bottomContent && <div className={bottomContentClasses}>{bottomContent}</div>}
    </>
  );
};

CollapsePanel.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  openCallback: PropTypes.func,
  title: PropTypes.string.isRequired,
  length: PropTypes.number,
  children: PropTypes.node.isRequired,
  preventToogleMobile: PropTypes.bool,
  topContent: PropTypes.node,
  bottomContent: PropTypes.node,
};

export default CollapsePanel;
