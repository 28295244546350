import client from './client';

import { mainAppSignInPath, mainAppSignOutPath } from '@Utils/routes.js.erb';

export const signInRequest = async (blockchainAddress, ethMessage, ethSignature) => {
  await client
    .post(mainAppSignInPath(), {
      blockchainAddress,
      ethMessage,
      ethSignature,
    })
    .catch(() => {
      // FIXME: Explore user session error handling with toasters https://www.npmjs.com/package/react-toastify
    });
};

export const signOutRequest = async () => {
  await client.delete(mainAppSignOutPath());
};
