import React from 'react';

import PropTypes from 'prop-types';

import TokensSort from './TokensSort';

import { Loader, TokenCard } from '@SharedComponents/';

import useInfiniteScroll from '@Hooks/useInfiniteScroll';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

const MarketplaceTokens = ({
  meta,
  marketplaceTokens,
  hasNextPage,
  isLoadingTokens,
  loadMoreTokens,
  sortBy,
}) => {
  const infiniteScrollRef = useInfiniteScroll(loadMoreTokens);

  const renderTokens = () => (
    <div className="marketplace-tokens-grid  mt-4">
      {marketplaceTokens.map((token) => (
        <TokenCard token={token} key={token.id} />
      ))}
    </div>
  );

  const { belowDesktop } = useGridBreakpoint();

  return (
    <>
      {belowDesktop ? (
        <>
          {sortBy && (
            <div className="g-col-12 d-flex flex-column marketplace-panel marketplace-panel-sort">
              <TokensSort sortBy={sortBy} />
            </div>
          )}

          <div className="g-col-12 d-flex flex-column p-5 mb-5 marketplace-panel">
            <h4 className="avenir-500 f-24 f-xs-16 f-sm-16 text-uppercase small-caps">
              All Tokens <span className="avenir-500 color-primary-blue">({meta.tokensCount})</span>
            </h4>
            {marketplaceTokens?.length > 0 ? (
              renderTokens()
            ) : (
              <div className="my-5 pt-3 pb-5 text-center">
                <h4 className="avenir-400 f-16 color-gray-300">No tokens to display yet.</h4>
              </div>
            )}

            {hasNextPage && <Loader ref={infiniteScrollRef} isLoading={isLoadingTokens} />}
          </div>
        </>
      ) : (
        <div className="g-col-12 g-col-lg-10 d-flex flex-column p-4 p-lg-5 marketplace-panel">
          <div className="grid g-col-12 pb-5">
            <div className="g-col-8 g-col-xxl-9">
              <h4 className="avenir-500 f-24 f-xs-16 f-sm-16 text-uppercase small-caps">
                All Tokens <span className="color-primary-blue">({meta.tokensCount})</span>
              </h4>
            </div>
            {sortBy && (
              <div className="g-col-4 g-col-xxl-3">
                <TokensSort sortBy={sortBy} />
              </div>
            )}
          </div>

          {marketplaceTokens?.length > 0 ? (
            renderTokens()
          ) : (
            <div className="my-5 pt-3 pb-5 text-center">
              <h4 className="avenir-400 f-16 color-gray-300">No tokens to display yet.</h4>
            </div>
          )}

          {hasNextPage && <Loader ref={infiniteScrollRef} isLoading={isLoadingTokens} />}
        </div>
      )}
    </>
  );
};

MarketplaceTokens.propTypes = {
  meta: PropTypes.shape({
    nextPage: PropTypes.bool,
    tokensCount: PropTypes.number,
  }).isRequired,
  marketplaceTokens: PropTypes.array,
  hasNextPage: PropTypes.bool,
  isLoadingTokens: PropTypes.bool,
  loadMoreTokens: PropTypes.func,
  sortBy: PropTypes.func,
};

export default MarketplaceTokens;
