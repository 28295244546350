import React from 'react';

import PropTypes from 'prop-types';

import ethLogo from '@Images/ethLogo.svg';

import { TokenListingShape } from '@Utils/propsShape';

const FixedCardLayout = ({ listing, topOfferAmount }) =>
  listing ? (
    <div className="d-flex flex-row token-price">
      <p className="avenir-600 f-16 small-caps color-gray-300">Price</p>
      <div className="d-flex flex-row align-items-center gap-2">
        <img className="token-price-image" src={ethLogo} alt="ethereum" width="25" height="25" />
        <p className="avenir-700 f-16">{listing.price}</p>
      </div>
    </div>
  ) : (
    <>
      {topOfferAmount ? (
        <div className="d-flex flex-row token-price">
          <div className="d-flex flex-column">
            <p className="avenir-600 f-16 small-caps color-gray-300">Top Offer</p>
          </div>
          <div className="d-flex flex-row align-items-center gap-2">
            <img
              className="token-price-image"
              src={ethLogo}
              alt="ethereum"
              width="25"
              height="25"
            />
            <p className="avenir-700 f-16">{topOfferAmount}</p>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row token-price justify-content-center">
          <p className="avenir-700 f-16">{'—'}</p>
        </div>
      )}
    </>
  );

FixedCardLayout.propTypes = {
  listing: TokenListingShape,
  topOfferAmount: PropTypes.number,
};

export default FixedCardLayout;
