import React from 'react';

import PropTypes from 'prop-types';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Avatar } from '@SharedComponents/';

import { mainAppArtistPath } from '@Routes';

import 'react-loading-skeleton/dist/skeleton.css';

const ArtistRow = ({ avatarUrl, name, artistSlug: slug, role = 'Music Artist', loading }) => (
  <a href={mainAppArtistPath(slug)} className="section-row d-flex flex-row gap-4">
    <div className="avatar-placement">
      <Avatar alt={`${name}-avatar`} src={avatarUrl} className="avatar-3" />
    </div>
    <div className="d-flex flex-row justify-between w-100">
      <div className="d-flex flex-column justify-between w-100 gap-2">
        {loading ? (
          <SkeletonTheme baseColor="#AAAAAA" highlightColor="#e0e0e0">
            <Skeleton />
            <Skeleton width="80%" />
          </SkeletonTheme>
        ) : (
          <>
            <p className="f-16 avenir-500 color-black truncate max-line-1">{name}</p>
            <p className="f-12 avenir-500 color-gray-200 truncate max-line-1">{role}</p>
          </>
        )}
      </div>
    </div>
  </a>
);

ArtistRow.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  artistSlug: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ArtistRow;
