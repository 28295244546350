import React, { createElement, lazy } from 'react';

import PropTypes from 'prop-types';

import { RailsContextShape, UserShape } from '@Utils/propsShape';

const PageWrapper = lazy(() => import('@MainAppComponents/PageWrapper'));

const ManagerPageWrapper = lazy(() => import('@ManagerAppComponents/PageWrapper'));

const PageRenderer = ({
  component,
  context,
  props: componentProps,
  mainApp = true,
  managerApp = false,
  enableThirdWeb,
}) => {
  const ethContext = window.ethereum ?? false;

  const hasThirdWebContext = !!(enableThirdWeb && ethContext);

  const props = { ...componentProps, hasThirdWebContext };

  if (context && mainApp)
    return (
      <PageWrapper {...props}>
        {createElement(context, props, createElement(component, props))}
      </PageWrapper>
    );

  if (context && managerApp)
    return (
      <ManagerPageWrapper {...props}>
        {createElement(context, props, createElement(component, props))}
      </ManagerPageWrapper>
    );

  if (!context && managerApp)
    return <ManagerPageWrapper {...props}>{createElement(component, props)}</ManagerPageWrapper>;

  return <PageWrapper {...props}>{createElement(component, props)}</PageWrapper>;
};

PageRenderer.propTypes = {
  component: PropTypes.func,
  context: PropTypes.func,
  props: PropTypes.shape({
    user: UserShape,
    footer: PropTypes.bool,
    enableThirdWebProvider: PropTypes.bool,
    children: PropTypes.node,
    railsContext: RailsContextShape,
  }),
  mainApp: PropTypes.bool,
  managerApp: PropTypes.bool,
  footer: PropTypes.bool,
  enableThirdWeb: PropTypes.bool,
};

export default PageRenderer;
