import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { deleteUserRequest, deleteUserShippingDetailsRequest, updateUserRequest } from '@Api/user';

const userDefaultShape = {
  address: {},
  avatarUrl: '',
  blockchainAddress: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  nonce: '',
  username: '',
};

const initialState = {
  user: { ...userDefaultShape },
  isSubmitting: false,
};

export const updateUser = createAsyncThunk(
  'currentUser/update',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const updatedUser = await updateUserRequest(id, payload);
      return updatedUser;
    } catch (user) {
      return rejectWithValue(user.errors);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'currentUser/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      const deletedUser = await deleteUserRequest(id);
      return deletedUser;
    } catch ({ user }) {
      return rejectWithValue(user.errors);
    }
  }
);

export const deleteShippingDetails = createAsyncThunk(
  'currentUser/deleteShippingDetails',
  async ({ id }, { rejectWithValue }) => {
    try {
      const user = await deleteUserShippingDetailsRequest(id);
      return user;
    } catch ({ user }) {
      return rejectWithValue(user.errors);
    }
  }
);

const currentUser = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload, meta }) => {
        state.isSubmitting = false;

        state.user = {
          ...state.user,
          ...payload.user,
        };

        // This should come through the `payload`, not the meta.args?
        const { avatarUrl, coverUrl } = meta.arg;

        if (avatarUrl) state.user.avatarUrl = avatarUrl;
        if (coverUrl) state.user.coverUrl = coverUrl;
      })
      .addCase(updateUser.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(deleteUser.fulfilled, () => {})
      .addCase(deleteUser.rejected, () => {});
  },
});

export const { setUser } = currentUser.actions;

export const getUser = (state) => state.currentUser;

export default currentUser.reducer;
