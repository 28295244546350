import React from 'react';

import { useSelector } from 'react-redux';

import feed from '@Images/feed.svg';

import favorites from '@Images/favorites.svg';

import tokens from '@Images/tokens.svg';

import offers from '@Images/offers.svg';

import settings from '@Images/settings.svg';

import logout from '@Images/logout.svg';

import SideNavigation from '@SharedComponents/SideNavigation';

import {
  mainAppFeedPath,
  mainAppMyTokensPath,
  mainAppFavoriteTokensPath,
  mainAppOffersPath,
  mainAppSettingsPath,
  mainAppSignOutPath,
} from '@Routes';

import { getUser } from '@Store/slices/currentUser';

const OPTIONS = {
  Overview: [
    {
      name: 'Feed',
      icon: feed,
      link: mainAppFeedPath(),
    },
    {
      name: 'My Tokens',
      icon: tokens,
      link: mainAppMyTokensPath(),
    },
    {
      name: 'Favorites',
      icon: favorites,
      link: mainAppFavoriteTokensPath(),
    },
    {
      name: 'Offers',
      icon: offers,
      link: mainAppOffersPath(),
    },
  ],
  Account: [
    {
      name: 'Settings',
      icon: settings,
      link: mainAppSettingsPath(),
    },
    {
      name: 'Log Out',
      icon: logout,
      link: mainAppSignOutPath(),
    },
  ],
};

const UserSideNavigation = () => {
  const {
    user: { username, avatarUrl },
  } = useSelector(getUser);

  return <SideNavigation data={{ username, avatarUrl }} options={OPTIONS} />;
};

export default UserSideNavigation;
