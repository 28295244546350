import React, { useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon, Avatar } from '..';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

import navigation from '@Utils/navigation';

const Menu = ({ avatarUrl, username, mobile, open, openMenu, options: menuOptions }) => {
  const path = window.location.pathname.split('/').filter((p) => p !== '');
  const currentPage = path[path.length - 1];

  const menuClasses = classNames('d-flex d-lg-block px-lg-4 menu', {
    mobile,
    open: open && mobile,
  });

  const backdropClasses = classNames('backdrop', {
    hidden: !open,
  });

  const navigateApp = (page) => {
    if (mobile) openMenu();
    navigation(page.toLowerCase());
  };

  return (
    <>
      <div className={menuClasses}>
        <div className="d-flex d-lg-none flex-column align-items-center gap-5">
          <Icon
            onClick={openMenu}
            name="keyboard_arrow_left"
            size="xl"
            className="close-icon color-dark"
            transparent
          />
          <Avatar src={avatarUrl} alt={`avatar_${username}`} className="avatar-12 avatar-border" />
          <h3 className="avenir-600 f-18 username">{username}</h3>
        </div>
        <div className="options-wrapper d-flex flex-column align-items-start align-self-center mb-5">
          {Object.entries(menuOptions).map(([menuTitle, opts]) => {
            return (
              <div className="options-section" key={menuTitle}>
                <h3 className="avenir-400 f-14 f-sm-16 f-xs-16 color-gray-300 text-uppercase small-caps menu-title mb-3">
                  {menuTitle}
                </h3>
                <ul className="section-list">
                  {opts.map((option, i) => {
                    const splitLink = option.link.split('/').filter((p) => p !== '');
                    const link = splitLink[splitLink.length - 1];

                    const listItems = classNames('d-flex align-items-center menu-item', {
                      'menu-item-active': link === currentPage,
                    });
                    return (
                      <li
                        role="presentation"
                        key={i}
                        className={listItems}
                        onClick={() => navigateApp(option.link)}
                      >
                        <img src={option.icon} alt="icon" />
                        <span className="avenir-500 f-18 f-xs-24 f-sm-24 f-md-24 m-0 mx-3 color-dark text-uppercase small-caps">
                          {option.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      {mobile && <div className={backdropClasses} />}
    </>
  );
};

Menu.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  open: PropTypes.bool,
  mobile: PropTypes.bool,
  openMenu: PropTypes.func,
  options: PropTypes.shape({
    src: PropTypes.string,
    username: PropTypes.string,
  }),
};

const SideNavigation = ({ data, options, layout = 'user' }) => {
  const { username, avatarUrl } = data;

  const [open, setIsOpen] = useState(false);
  const { belowDesktop: mobile } = useGridBreakpoint();

  const openMenu = () => {
    if (mobile) setIsOpen((state) => !state);
  };

  return (
    <aside className="sidebar-nav w-100">
      {layout === 'manager' ? (
        <div className="d-flex flex-row justify-content-start align-items-center justify-content-lg-evenly align-items-lg-center manager-id">
          <div className="d-flex flex-row gap-3 align-items-center w-100 align-items-center">
            <Avatar
              src={avatarUrl}
              className="avatar-xs-8 avatar-sm-8 avatar-md-8 avatar-4 avatar-border"
              alt="avatar_image"
              onClick={openMenu}
            />
            <h6 className="archivo-600 f-24 d-none d-lg-block">{username}</h6>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-start justify-content-lg-center align-items-start align-items-lg-center content px-2 pt-5 px-lg-0 user-id">
          <Avatar
            src={avatarUrl}
            alt="avatar_image"
            onClick={openMenu}
            className="avatar-md-7 avatar-lg-13 avatar-border"
          />
          <h6 className="avenir-600 f-18 mt-3 mb-1 d-none d-lg-block text-uppercase menu-username mt-5 mt-lg-3 ">
            {username}
          </h6>
        </div>
      )}
      <Menu {...{ username, avatarUrl, open, mobile, openMenu, options }} />
    </aside>
  );
};

SideNavigation.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  options: PropTypes.any,
  layout: PropTypes.oneOf(['user', 'manager']),
};

export default SideNavigation;
