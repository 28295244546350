import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Table } from '@SharedComponents/';

import ETHIcon from '@Images/ethLogo.svg';

import { formatDollar } from '@Utils/format';

const FEES_COLUMNS = [
  {
    Header: 'Receiver',
    accessor: 'receiver',
  },
  {
    Header: 'Part',
    accessor: 'part',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
];

const Fees = ({ artistFee = 0, spreadmintFee = 0, saleFee = 0, value: { eth, dollars } }) => {
  const [data, setData] = useState([]);
  const userFee = saleFee === 0 ? 1 - artistFee - spreadmintFee : saleFee;

  const calculateFees = useCallback(() => {
    const calculatedFees = [
      {
        id: 1,
        receiver: 'Artist',
        part: `${artistFee * 100}%`,
        value: formatDollar(artistFee * dollars),
      },
      {
        id: 2,
        receiver: 'Spreadmint',
        part: `${spreadmintFee * 100}%`,
        value: formatDollar(spreadmintFee * dollars),
      },
      {
        id: 3,
        receiver: 'You',
        part: `${userFee * 100}%`,
        value: formatDollar(userFee * dollars),
      },
    ];
    setData(calculatedFees);
  }, [artistFee, dollars, spreadmintFee, userFee]);

  useEffect(() => {
    if (artistFee && spreadmintFee) calculateFees();
  }, [calculateFees, artistFee, spreadmintFee]);

  return (
    <div className="d-flex flex-column gap-1">
      <h5 className="avenir-500 color-primary-blue f-16 mb-2">Fees</h5>
      {data.length > 0 ? (
        <>
          <Table columns={FEES_COLUMNS} initialData={data} pagination={false} />
          <hr className="mt-4" />
          <div className="d-flex flex-row justify-content-between align-items-center mt-4">
            <h6 className="avenir-600 f-12 color-dark">Total Earnings</h6>
            <div className="d-flex flex-row align-items-center gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <img
                  className="token-price-image"
                  src={ETHIcon}
                  alt="Ethereum Icon"
                  height={28}
                  width={28}
                />
                <p className="avenir-600 f-16 color-dark">{(eth * userFee).toFixed(4)}</p>
              </div>
              <p className="avenir-400 f-12 color-gray-200">({formatDollar(dollars * userFee)})</p>
            </div>
          </div>
        </>
      ) : (
        <p className="nunito-400 f-16 color-dark text-center py-5">
          There was an error while calculating the token fees.
        </p>
      )}
    </div>
  );
};

Fees.propTypes = {
  artistFee: PropTypes.number,
  spreadmintFee: PropTypes.number,
  saleFee: PropTypes.number,
  value: PropTypes.shape({
    eth: PropTypes.number,
    dollars: PropTypes.number,
  }),
};

export default Fees;
