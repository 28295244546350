import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { updateManagerSettings, deleteManagerAccount } from '@Api/manager_settings';

const managerDefaultShape = {
  avatarUrl: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  password: '',
};

const initialState = {
  manager: { ...managerDefaultShape },
  isSubmitting: false,
};

export const updateManager = createAsyncThunk(
  'currentManager/update',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const updatedManager = await updateManagerSettings(id, payload);
      return updatedManager;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const deleteManager = createAsyncThunk(
  'currentManager/delete',
  async ({ rejectWithValue }) => {
    try {
      const deletedManager = await deleteManagerAccount();
      return deletedManager;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const currentManager = createSlice({
  name: 'currentManager',
  initialState,
  reducers: {
    setManager: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateManager.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(updateManager.fulfilled, (state, { payload, meta }) => {
        state.isSubmitting = false;

        state.manager = {
          ...state.manager,
          ...payload,
        };

        // This should come through the `payload`, not the meta.args?
        const { avatarUrl } = meta.arg;

        if (avatarUrl) state.manager.avatarUrl = avatarUrl;
      })
      .addCase(updateManager.rejected, (state) => {
        state.isSubmitting = false;
      });
  },
});

export const { setManager } = currentManager.actions;

export const getManager = (state) => state.currentManager;

export default currentManager.reducer;
