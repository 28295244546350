import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { formatDistance } from 'date-fns';

import { Avatar, Image } from '..';

import { PostShape } from '@Utils/propsShape';

import { BlockedPost } from '@MainAppComponents/';

const timeAgo = (postDate, currentDate = new Date()) => {
  return formatDistance(postDate, currentDate, { addSuffix: true });
};

const Post = ({ post, mint }) => {
  const { artist, mediaFilesUrls, content, createdAt, updatedAt, title, tiers, tierBlocked } = post;

  const postDate = new Date(createdAt);

  const tierPostBlockedClasses = classNames('w-auto', {
    'post-blocked': tierBlocked,
    'mb-4': !tierBlocked,
  });

  const postWrapper = classNames('w-100 mt-3', {
    'mb-5 pb-3': !tierBlocked,
  });

  const imageUrl = mediaFilesUrls?.[0];

  return (
    <div className="post">
      <span className="avenir-500 f-16 mb-3 bg-white-600 p-3 w-auto color-gray-300 post-tag">
        {tiers.length > 0 ? (
          <>
            This post is available for:{' '}
            {tiers.map((tier, index) => {
              if (index === tiers.length - 1) return tier.title;
              return `${tier.title} & `;
            })}
          </>
        ) : (
          <>This post is available for: Everyone</>
        )}
      </span>
      <div className={postWrapper}>
        <div className="d-flex flex-row align-items-start gap-3 mt-4 mb-4">
          <Avatar
            className="avatar-4"
            src={artist.avatarUrl}
            alt={`image_${title.trim().toLowerCase()}`}
          />
          <div className="post-header">
            <div className="d-block avenir-500 f-12 color-gray-400 post-time">
              <div className="d-flex flex-column align-items-lg-end">
                <span>{`Posted ${timeAgo(postDate)}`}</span>
                {updatedAt > createdAt && <span>(Edited)</span>}
              </div>
            </div>

            <div className="flex-grow-1">
              <a href={`/artists/${artist.slug}`} className="archivo-500 f-16 color-primary-blue">
                {artist.name}
              </a>
              <h4 className="archivo-600 f-18 color-black">{title}</h4>
            </div>
          </div>
        </div>

        <div className={tierPostBlockedClasses}>
          {tierBlocked ? (
            <BlockedPost {...{ post, mint }} />
          ) : (
            mediaFilesUrls.length > 0 && (
              <Image
                className="w-100 post-image"
                src={imageUrl}
                alt={`image_${title.trim().toLowerCase()}`}
              />
            )
          )}
        </div>
        {!tierBlocked && <p className="avenir-400 f-16 color-dark">{content}</p>}
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PostShape,
  mint: PropTypes.func,
};

export default Post;
