import { createBreakpoint } from 'react-use';

const useBreakpoint = createBreakpoint({
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});

const useGridBreakpoints = () => {
  const currentBreakpoint = useBreakpoint();

  const belowDesktop = ['sm', 'md'].includes(currentBreakpoint);

  return {
    currentBreakpoint,
    belowDesktop,
  };
};

export default useGridBreakpoints;
