import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from '@SharedComponents/';

import errorImage from '@Images/error.svg';

const ErrorModal = ({
  onClose,
  visible,
  title = 'Something went wrong...',
  description = 'There was a problem performing your action. Please try again.',
}) => (
  <Modal visible={visible} className="d-flex flex-column gap-5" onClose={onClose} closeIcon>
    <img src={errorImage} alt="Error illustration" />
    <h6 className="archivo-600 f-24 text-center">{title}</h6>
    <p className="nunito-400 f-16 lh-1 text-center">{description}</p>
  </Modal>
);

ErrorModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ErrorModal;
