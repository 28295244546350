import React from 'react';

import PropTypes from 'prop-types';

import ArtistRow from './artistRow';
import TokenRow from './tokenRow';

const SearchResultSection = ({ sectionName, rowData, loading }) => {
  let Row = null;

  switch (sectionName) {
    case 'tokens':
      Row = TokenRow;
      break;
    case 'artists':
      Row = ArtistRow;
      break;
    default:
      break;
  }

  const props = { ...rowData, loading };

  return Row !== null ? Row(props) : <></>;
};

SearchResultSection.propTypes = {
  sectionName: PropTypes.string,
  rowData: PropTypes.object,
  loading: PropTypes.bool,
};

export default SearchResultSection;
