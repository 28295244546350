import { useCallback, useState } from 'react';

/**
 * @param callbackParam expected to be a promise
 */

const useInfiniteScroll = (callbackParam) => {
  const [isLoading, setIsLoading] = useState(false);

  const callback = useCallback(
    (entries) => {
      if (!isLoading && entries[0].isIntersecting) {
        setIsLoading(true);
        // eslint-disable-next-line promise/catch-or-return
        callbackParam().finally(() => setIsLoading(false));
      }
    },
    [callbackParam, isLoading]
  );

  const infiniteScrollRef = useCallback(
    (node) => {
      if (!node) return;
      const intersectionObserver = new IntersectionObserver(callback, { threshold: 1 });
      intersectionObserver.observe(node);
    },
    [callback]
  );

  return infiniteScrollRef;
};

export default useInfiniteScroll;
