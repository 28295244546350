import React, { useState, useEffect, useRef, useMemo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { useClickAway, useDebounce } from 'react-use';

import SearchResultSection from './SearchResultSection';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

import searchRequest from '@Api/marketplace_search';

import navigation from '@Utils/navigation';

import { mainAppMarketplaceSearchResultsPath } from '@Routes';

import Loader from '@SharedComponents/Loader';

const SearchBar = ({
  value = '',
  disabled = false,
  dark = false,
  min = false,
  open,
  setOpen,
  ...rest
}) => {
  const [isTyping, setIsTyping] = useState(false);

  const [inputValue, setInputValue] = useState(value);

  const [searchResults, setSearchResults] = useState({});

  const [showResultsPanel, setShowResultsPanel] = useState(false);

  const { belowDesktop } = useGridBreakpoint();

  const searchArea = useRef(null);

  const searchInputRef = useRef(null);

  const handleInputFocus = () => searchInputRef?.current?.focus();

  const searchResultsPage = mainAppMarketplaceSearchResultsPath({ query: inputValue });

  const minInputLength = inputValue.length >= 3;

  const handleOpenSearch = () => {
    if (!disabled && setOpen) setOpen(true);
  };

  const handleCloseSearch = () => {
    setSearchResults({});
    setInputValue('');
    if (open && belowDesktop) {
      setOpen(false);
    }
  };

  const handleSearchIconInteraction = () => {
    if (open) {
      handleCloseSearch();
    } else {
      handleOpenSearch();
    }
  };

  const handleKeyDown = (event) => {
    if (!disabled && event.code === 'Enter') {
      handleCloseSearch();
      navigation(searchResultsPage);
    }
  };

  const handleSearchInput = async () => {
    if (minInputLength) {
      const res = await searchRequest({
        query: inputValue,
      });
      setSearchResults(res);
    } else {
      setSearchResults({});
    }
  };

  const hasResults = useMemo(() => {
    const groupSectionHasLength =
      Object.keys(searchResults).length > 0 &&
      Object.keys(searchResults).map((section) => searchResults[section].length !== 0);

    if (Array.isArray(groupSectionHasLength)) {
      return !groupSectionHasLength.every((section) => section === false);
    }

    return false;
  }, [searchResults]);

  const searchInputClasses = classNames('search-input-group p-relative', {
    'style-dark': dark,
    'search-open': open,
    disabled,
    min,
  });

  const searchResultVisibility = classNames('search-results', {
    open: minInputLength && showResultsPanel,
    min,
  });

  useDebounce(
    () => {
      setIsTyping(false);
      handleSearchInput();
    },
    1000,
    [inputValue]
  );

  useClickAway(searchArea, () => {
    setShowResultsPanel(false);
  });

  useEffect(() => {
    if (open) handleInputFocus();
  }, [open]);

  return (
    <div className={searchInputClasses} ref={searchArea}>
      <div className="input avenir-400">
        <input
          ref={searchInputRef}
          value={inputValue}
          onFocus={() => setShowResultsPanel(true)}
          onChange={(event) => {
            setIsTyping(true);
            setInputValue(event.currentTarget.value);
          }}
          onKeyPress={handleKeyDown}
          disabled={disabled}
          {...rest}
        />
        <span
          role="link"
          tabIndex={0}
          className="material-icons input-icon"
          onClick={
            belowDesktop
              ? handleSearchIconInteraction
              : () => {
                  if (inputValue.length > 0) navigation(searchResultsPage);
                }
          }
          onKeyDown={handleKeyDown}
        >
          search
        </span>
      </div>
      <div className={searchResultVisibility}>
        {hasResults && (
          <div className="has-results d-flex flex-column justify-content-between">
            <div className="d-flex flex-column justify-content-start">
              {Object.keys(searchResults).map((sectionName) => {
                const sectionRow = searchResults[sectionName];
                if (sectionRow.length > 0)
                  return (
                    <section className={`search-${sectionName}`} key={`search-${sectionName}`}>
                      <h1 className="bg-white-600 avenir-600 f-16 text-uppercase small-caps">
                        {sectionName}
                      </h1>
                      <ul className={`${sectionName}-row d-flex flex-column gap-1 py-2`}>
                        {sectionRow.map((rowData, index) => (
                          <li key={`search-${sectionName}-${index}`}>
                            <SearchResultSection {...{ sectionName, rowData, loading: isTyping }} />
                          </li>
                        ))}
                      </ul>
                    </section>
                  );
                return <></>;
              })}
            </div>
            <a
              className="avenir-500 f-16 color-secondary-blue py-3 justify-self-end"
              href={searchResultsPage}
            >
              Show all results
            </a>
          </div>
        )}
        {!hasResults && isTyping && (
          <div className="loader-area">
            <Loader isLoading />
          </div>
        )}
        {!isTyping && !hasResults && (
          <div className="no-results">
            <p className="avenir-400 f-14 color-gray-300">No results to display.</p>
          </div>
        )}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  dark: PropTypes.bool,
  min: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SearchBar;
