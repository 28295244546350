import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { KEY_ESCAPE_CODE } from '@Lib/constants';

const Tag = ({
  label,
  close,
  disabled,
  callback,
  className,
  // eslint-disable-next-line react/prop-types
  as: Component = 'button',
  ...rest
}) => {
  const handleCloseCallback = () => {
    if (callback && !disabled) callback();
  };

  const handleKeyDown = (event) => {
    if (event.keycode === KEY_ESCAPE_CODE) handleCloseCallback();
  };

  const tagClasses = classNames('tag', className);

  return (
    <Component type="button" className={tagClasses} disabled={disabled} {...rest}>
      <span
        className="truncate max-line-1"
        style={{
          marginTop: '0.3rem',
        }}
      >
        {label}
      </span>
      {close && !disabled && (
        <span
          role="link"
          tabIndex={0}
          className="icon-close material-icons"
          onClick={handleCloseCallback}
          onKeyDown={handleKeyDown}
        >
          close
        </span>
      )}
    </Component>
  );
};

Tag.propTypes = {
  label: PropTypes.string,
  close: PropTypes.bool,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  className: PropTypes.string,
};

export default Tag;
