import React from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-named-default
import { default as SlickSlider } from 'react-slick';

import SlickArrow from './arrow';

import 'slick-carousel/slick/slick.scss';

import 'slick-carousel/slick/slick-theme.scss';

const DEFAULT_SETTINGS = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  width: 'auto',
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        width: '30rem',
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Slider = ({ settings, children }) => {
  const sliderSettings = {
    ...DEFAULT_SETTINGS,
    ...settings,
    nextArrow: <SlickArrow direction="right" />,
    prevArrow: <SlickArrow direction="left" />,
  };

  return <SlickSlider {...sliderSettings}>{children}</SlickSlider>;
};

Slider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.shape(),
};

export default Slider;
