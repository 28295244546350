import React, { useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { formatDate, dateFormats } from '@Utils/date';

import { Icon, Table, Dropdown, Image } from '@SharedComponents/';

import { HISTORY_FILTER_BY } from '@Lib/constants';

import { fetchOperationsRequest } from '@Api/operations';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

import { formatDollar } from '@Utils/format';

const CollapsePanelHistory = ({
  token,
  operations,
  meta,
  open = false,
  openCallback,
  children,
  title,
  length,
  bottomContent,
  fadeOut = false,
}) => {
  const [controlledOpen, setControlledOpen] = useState(open);

  const [selectedFilter, setSelectedFilter] = useState('');

  const [errorMessage] = useState();

  const columnsHistory = useMemo(() => {
    const cols = [
      {
        Header: 'Type',
        accessor: 'type',
        className: 'type-col',
      },
      {
        Header: 'Price',
        accessor: 'amount',
        className: 'amount-col',
        Cell: (tableProps) => <p>{tableProps.cell.value} ETH</p>,
      },
      {
        Header: 'USD Price',
        accessor: 'dollarAmount',
        className: 'amount-col',
        Cell: (tableProps) => formatDollar(tableProps.cell.value),
      },
      {
        Header: 'Seller',
        accessor: 'seller.username',
        className: 'seller-col',
        Cell: (tableProps) => (
          <a role="row" href="#!" className="avenir-600 color-primary-blue f-12">
            {tableProps.cell.value}
          </a>
        ),
      },
      {
        Header: 'Buyer',
        accessor: 'buyer.username',
        className: 'buyer-col',
        Cell: (tableProps) => (
          <a role="row" href="#!" className="avenir-600 color-primary-blue f-12">
            {tableProps.cell.value}
          </a>
        ),
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: (tableProps) => (
          <a
            role="row"
            href={tableProps.row.original.transactionLink}
            className="avenir-600 color-primary-blue f-12"
          >
            {formatDate(
              tableProps.row.original.createdAt || tableProps.row.original.expiresAt,
              dateFormats.AbbreviatedDate
            )}
          </a>
        ),
      },
    ];
    if (operations[0]?.type === 'Offer') {
      const offerCols = [...cols];
      delete offerCols[2];
      return offerCols;
    }
    return cols;
  }, [operations]);

  const handleCollapsePanel = () => setControlledOpen((state) => !state);

  const handleFilter = ({ historyFilter }) => setSelectedFilter(historyFilter);

  const fadeOutPanel = classNames('panel panel-history', {
    'fade-out': fadeOut,
    'fade-in': !fadeOut,
  });

  const panelClasses = classNames('collapse-panel-history collapse-body px-4', {
    collapsed: !controlledOpen,
  });

  const bottomContentClasses = classNames('collapse-panel-history', {
    collapsed: !controlledOpen,
  });

  const imageClasses = classNames('token-image', {
    collapsed: controlledOpen,
  });

  useEffect(() => {
    if (openCallback) {
      openCallback(controlledOpen);
    }
  }, [controlledOpen, openCallback]);

  const { belowDesktop } = useGridBreakpoint();

  return (
    <>
      {!belowDesktop && <Image className={imageClasses} src={token.imageUrl} alt="Token" />}
      <div className={fadeOutPanel}>
        <div
          role="presentation"
          className="d-flex flex-row justify-content-between align-items-center panel-title collapse-panel-header"
          onClick={handleCollapsePanel}
        >
          <h3 className="avenir-600 f-16 small-caps">
            {title}
            {length && <span className="color-primary-blue px-1">({length})</span>}
          </h3>
          <div role="presentation">
            <span className="avenir-500 f-16 color-primary-blue text-uppercase small-caps cursor-pointer">
              <Icon
                name={`keyboard_arrow_${controlledOpen ? 'up' : 'down'}`}
                size="md"
                className="color-primary-black h-auto"
              />
            </span>
          </div>
        </div>
        <div className={panelClasses}>
          <div className="d-flex flex-column mt-4 gap-3">
            <div className="grid">
              <div className="g-col-12 g-col-lg-4">
                <Dropdown
                  label="Filter by"
                  name="historyFilter"
                  data={Object.values(HISTORY_FILTER_BY)}
                  selected={selectedFilter}
                  onClick={handleFilter}
                  error={errorMessage}
                />
              </div>
            </div>
            <Table
              className="table-history"
              initialData={operations}
              columns={columnsHistory}
              pagination={true}
              banding={false}
              requestData={fetchOperationsRequest}
              params={{ tokenId: token.id, selectedFilter: [selectedFilter] }}
              pageCount={meta.pageCount}
              emptyState={() => {
                return (
                  <div className="d-flex flex-column py-5 h-100 justify-content-center align-items-center gap-5">
                    <p className="avenir-500 f-16 text-center color-gray-200">
                      {selectedFilter === 'default'
                        ? 'No transaction data available'
                        : `The selected filter for ${selectedFilter} does not hold any data`}
                    </p>
                  </div>
                );
              }}
            />
          </div>
          {children}
        </div>
        {bottomContent && <div className={bottomContentClasses}>{bottomContent}</div>}
      </div>
    </>
  );
};

CollapsePanelHistory.propTypes = {
  open: PropTypes.bool,
  openCallback: PropTypes.func,
  title: PropTypes.string.isRequired,
  length: PropTypes.number,
  children: PropTypes.node.isRequired,
  bottomContent: PropTypes.node,
  token: PropTypes.shape(),
  fadeOut: PropTypes.bool,
  operations: PropTypes.array,
  meta: PropTypes.shape({
    pageCount: PropTypes.number,
  }),
};

export default CollapsePanelHistory;

/* eslint-enable jsx-a11y/anchor-is-valid */
