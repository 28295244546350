import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({ activeTab, title, onClick, index }) => {
  const handleTabClick = () => {
    onClick(index);
  };

  const tabClasses = classNames('tab-list-item', {
    'tab-list-active': activeTab === index,
  });

  return (
    <li role="presentation" className={tabClasses} onClick={handleTabClick}>
      {title}
    </li>
  );
};

const TabGroup = ({ className, children, index = 0 }) => {
  const [activeTab, setActiveTab] = useState(index);

  const onClickTabItem = (i) => {
    setActiveTab(i);
  };

  const tabContainerClasses = classNames('tabs', className);

  return (
    <div className={tabContainerClasses}>
      <ol className="tab-list">
        {children.map((child, i) => {
          const { title } = child.props;
          return (
            <Tab activeTab={activeTab} key={i} title={title} onClick={onClickTabItem} index={i} />
          );
        })}
      </ol>
      <div className="tab-content">
        {children.map((child, i) => {
          if (i !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  activeTab: PropTypes.number,
  index: PropTypes.number,
};

TabGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
};

export { TabGroup, Tab };
