import React from 'react';

import { SVGIconsShape } from '@Utils/propsShape';

const InstagramIcon = ({ width, height }) => (
  <svg
    {...{ width, height }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 46"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1C0.895431 1 0 1.89543 0 3V44C0 45.1046 0.895431 46 2 46H43C44.1046 46 45 45.1046 45 44V3C45 1.89543 44.1046 1 43 1H2ZM32.1882 7.0874H11.8589C8.11663 7.0874 5.04712 10.1144 5.04712 13.8772V34.0894C5.04712 37.8522 8.11663 40.8791 11.8589 40.8791H32.1882C35.9305 40.8791 39 37.8522 39 34.0894V13.8772C39 10.1144 35.9305 7.0874 32.1882 7.0874ZM32.1882 37.8791C34.29 37.8791 36 36.179 36 34.0894V13.8772C36 13.2647 35.8531 12.6857 35.5925 12.1732C35.2339 11.468 34.6601 10.8886 33.9575 10.521C33.4285 10.2442 32.8264 10.0874 32.1882 10.0874H11.8589C9.75711 10.0874 8.04712 11.7875 8.04712 13.8772V34.0894C8.04712 36.179 9.75711 37.8791 11.8589 37.8791H32.1882ZM22.0235 15.1404C23.2244 15.1404 24.3705 15.3784 25.4166 15.8093C27.6021 16.7096 29.3513 18.4521 30.2522 20.6273C30.681 21.6627 30.9176 22.796 30.9176 23.9833C30.9176 28.8587 26.9272 32.8261 22.0235 32.8261C17.1199 32.8261 13.1295 28.8587 13.1295 23.9833C13.1295 19.1079 17.1199 15.1404 22.0235 15.1404ZM33.4588 15.1404C33.4588 15.6779 33.289 16.1768 33 16.5869C32.7349 16.9629 32.3695 17.2642 31.9429 17.4521C31.6291 17.5902 31.2822 17.667 30.9176 17.667C29.5167 17.667 28.3765 16.5333 28.3765 15.1404C28.3765 14.7759 28.4546 14.4291 28.595 14.1157C28.78 13.703 29.0731 13.3483 29.4383 13.0874C29.8553 12.7895 30.3663 12.6139 30.9176 12.6139C31.3801 12.6139 31.8142 12.7375 32.1882 12.9531C32.2821 13.0072 32.3721 13.0671 32.4579 13.1323C32.649 13.2776 32.8189 13.4492 32.962 13.6415C33.0181 13.717 33.0701 13.7957 33.1176 13.8772C33.3345 14.249 33.4588 14.6806 33.4588 15.1404ZM22.0235 29.8261C18.7604 29.8261 16.1295 27.1855 16.1295 23.9833C16.1295 20.7811 18.7604 18.1404 22.0235 18.1404C25.2867 18.1404 27.9176 20.7811 27.9176 23.9833C27.9176 27.1855 25.2867 29.8261 22.0235 29.8261Z"
      fill="currentColor"
    />
  </svg>
);

InstagramIcon.propTypes = SVGIconsShape.isRequired;

export default InstagramIcon;
