import { useState } from 'react';

import fetchMyTokensRequest from '../api/my_tokens';

const useMyTokens = (initialTokens, initialMeta) => {
  const [myTokens, setMyTokens] = useState(initialTokens);
  const [hasNextPage, setHasNextPage] = useState(initialMeta.nextPage);
  const [isLoadingTokens, setIsLoadingTokens] = useState(false);

  const loadMoreTokens = async () => {
    if (hasNextPage) {
      setIsLoadingTokens(true);

      const response = await fetchMyTokensRequest(myTokens.length);

      setMyTokens((prev) => [...prev, ...response.tokens]);
      setHasNextPage(response?.meta.nextPage);

      setIsLoadingTokens(false);
    }
  };

  return {
    myTokens,
    hasNextPage,
    isLoadingTokens,
    loadMoreTokens,
  };
};

export default useMyTokens;
