import client from './client';

import { managerAppArtistSettingsPath } from '@Utils/routes.js.erb';

const mapFormDataToParams = ({
  name,
  description,
  slug,
  facebook,
  instagram,
  linkedin,
  website,
  twitter,
  avatarUrl,
  coverUrl,
  avatarChanged,
  coverChanged,
}) => {
  const params = {
    name,
    description,
    slug,
    socialLinks: {
      facebook,
      instagram,
      linkedin,
      website,
      twitter,
    },
  };

  if (avatarChanged) params.avatar = { data: avatarUrl };
  if (coverChanged) params.cover = { data: coverUrl };

  return params;
};

const updateArtistSettings = async (id, payload) => {
  const params = mapFormDataToParams(payload);
  return client.patch(managerAppArtistSettingsPath(id), {
    artist: params,
  });
};

export default updateArtistSettings;
