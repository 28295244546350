import React from 'react';

import { SVGIconsShape } from '@Utils/propsShape';

const TwitterIcon = ({ width, height }) => (
  <svg {...{ width, height }} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1C0.895431 1 0 1.89543 0 3V44C0 45.1046 0.89543 46 2 46H43C44.1046 46 45 45.1046 45 44V3C45 1.89543 44.1046 1 43 1H2ZM33.3545 18.198C33.3545 17.9576 33.3495 17.7184 33.3392 17.4802C34.3808 16.6964 35.2861 15.718 36 14.6041C35.0443 15.0464 34.0159 15.3443 32.9367 15.4787C34.0382 14.7909 34.8836 13.7032 35.2826 12.4069C34.2517 13.043 33.1105 13.5053 31.8952 13.7549C30.9217 12.6753 29.5358 12 28.0013 12C25.0554 12 22.6663 14.4874 22.6663 17.5534C22.6663 17.989 22.7135 18.4129 22.805 18.8198C18.3714 18.5875 14.44 16.3774 11.8093 13.0168C11.3511 13.8376 11.0869 14.7909 11.0869 15.8084C11.0869 17.7354 12.0285 19.4363 13.4608 20.4314C12.5856 20.4033 11.7636 20.153 11.0447 19.7369C11.044 19.7601 11.044 19.7826 11.044 19.8077V19.8078C11.044 22.4971 12.8828 24.7431 15.3237 25.2518C14.8754 25.3792 14.4038 25.4475 13.9169 25.4475C13.574 25.4475 13.2395 25.4121 12.9143 25.3471C13.5938 27.5539 15.5627 29.1596 17.8979 29.2046C16.072 30.6944 13.7718 31.582 11.2724 31.582C10.8422 31.582 10.4174 31.5565 10 31.5052C12.3608 33.0807 15.1641 34 18.1767 34C27.9889 33.9996 33.3545 25.5369 33.3545 18.198Z"
      fill="currentColor"
    />
  </svg>
);

TwitterIcon.propTypes = SVGIconsShape.isRequired;

export default TwitterIcon;
