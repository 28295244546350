import client from './client';

export const getFavoriteTokens = async ({ offset = 0 }) =>
  client.get(`/favorite_tokens/?offset=${offset}`);

export const addFavoriteToken = async (id) => {
  return client.post(`/favorite_tokens`, { id });
};

export const removeFavoriteToken = async (id) => {
  return client.delete(`/favorite_tokens/${id}`);
};
