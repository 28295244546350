import { configureStore } from '@reduxjs/toolkit';

import currentUser from './slices/currentUser';

import currentManager from './slices/currentManager';

import currentArtist from './slices/currentArtist';

const store = configureStore({
  reducer: {
    currentUser,
    currentManager,
    currentArtist,
  },
});

export default store;
