import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  label,
  type = 'button',
  icon = false,
  secondary = false,
  tertiary = false,
  outline = false,
  disabled = false,
  submitting = false,
  as: Component = 'button',
  ...rest
}) => {
  const buttonClasses = classNames(
    'button-default',
    {
      'primary-style': !disabled && !secondary && !tertiary,
      'secondary-style': !disabled && secondary,
      'tertiary-style': tertiary,
      'button-padding': !tertiary,
      outline,
    },
    rest.className
  );

  const iconClasses = classNames('icon', {
    'material-icons-outlined': outline,
    'material-icons': !outline,
  });

  return (
    <Component {...rest} type={type} className={buttonClasses} disabled={disabled || submitting}>
      {icon && !submitting && <span className={iconClasses}>{icon}</span>}
      {submitting && <div className="loader" />}
      <span className="truncate max-line-1">{label}</span>
    </Component>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  icon: PropTypes.string,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  as: PropTypes.node,
};

export default Button;
