import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import PLACEHOLDER_SILHOUTTE from '@Images/silhouette_3.png';

import Image from '@SharedComponents/Image';

const Avatar = ({ className, alt, loading = false, src, ...rest }) => {
  const avatarImage = src || PLACEHOLDER_SILHOUTTE;

  const avatarClasses = classNames('avatar', className, { loading });

  const spans = new Array(8).fill(1);

  return loading ? (
    <div className={avatarClasses}>
      <div className="preloader">
        {spans.map((_el, i) => (
          <span key={i} />
        ))}
      </div>
    </div>
  ) : (
    <Image className={avatarClasses} round {...{ alt, src: avatarImage, ...rest }} />
  );
};

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default Avatar;
