import React, { useState } from 'react';

import classNames from 'classnames';

import { Button, Image } from '..';

import PLACEHOLDER_IMAGE from '@Images/silhouette_3.png';

import { ArtistShape, TierShape } from '@Utils/propsShape';

import navigation from '@Utils/navigation';

import { mainAppMarketplacePath } from '@Routes';

const TierCard = ({
  artist,
  tier: { id, title, description, imageUrl, benefits, mintedCount, totalCount },
}) => {
  const [flipped, setFlipped] = useState(false);

  const flipCardClasses = classNames('card-wrapper', {
    flipped,
  });

  const goToMarketplace = () =>
    navigation(mainAppMarketplacePath({ artists: [artist.id], tier: id }));

  const showFront = () => setFlipped(false);

  const showBack = () => setFlipped(true);

  return (
    <div className="container-flip d-flex flex-column align-items-center w-100 prevent-select">
      <div className={flipCardClasses}>
        <div
          role="presentation"
          className="card-face card-face-front panel p-4 w-100"
          onMouseOver={showBack}
          onTouchEnd={showBack}
          onFocus={showBack}
        >
          <div className="inner-wrapper h-100">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex flex-column align-items-center">
                <Image
                  src={imageUrl || PLACEHOLDER_IMAGE}
                  alt={`image_tier_${title}`}
                  width={100}
                  height={100}
                  className="mb-3"
                />
                <h6 className="avenir-600 f-14 mb-3 color-primary-blue text-uppercase">{`By ${artist.name}`}</h6>
                <h3 className="avenir-600 f-24 mb-2 text-uppercase text-center truncate w-100">
                  {title}
                </h3>
                <p className="avenir-400 f-12 mb-2 text-center w-100 truncate max-line-2">
                  {description}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="tier-tokens-pulled py-3 px-5 radius">
                  <h5 className="avenir-500 f-16 py-1">{`${mintedCount}/${totalCount} pulled`}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          role="presentation"
          className="card-face card-face-back panel w-100 p-5"
          onMouseLeave={showFront}
          onTouchEnd={showFront}
        >
          <div className="inner-wrapper h-100 d-flex flex-column align-items-center">
            <div className="align-self-start w-100 h-100">
              <h6 className="avenir-600 f-14 color-white text-uppercase mb-2 truncate w-100">
                {title}
              </h6>
              {benefits.length > 0 && (
                <>
                  <h3 className="avenir-600 f-24 color-white text-uppercase mb-3">Rewards</h3>
                  <ul className="h-100">
                    {benefits.map((benefit, i) => (
                      <li className="avenir-500 f-12 color-white lh-lg" key={`benefit_${i}`}>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <Button label="Marketplace" className="color-white" outline onClick={goToMarketplace} />
          </div>
        </div>
      </div>
    </div>
  );
};

TierCard.propTypes = {
  artist: ArtistShape.isRequired,
  tier: TierShape.isRequired,
};

export default TierCard;
