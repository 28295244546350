import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import updateArtistSettings from '@Api/update_artist_settings';

const artistDefaultShape = {
  name: '',
  avatarUrl: '',
  coverUrl: '',
  description: '',
  slug: '',
  socialLinks: {
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
    website: '',
  },
};

const initialState = {
  artist: { ...artistDefaultShape },
  isSubmitting: false,
};

export const updateArtist = createAsyncThunk(
  'currentArtist/update',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const updatedArtist = await updateArtistSettings(id, payload);
      return updatedArtist;
    } catch ({ artist }) {
      return rejectWithValue(artist.errors);
    }
  }
);

const currentArtist = createSlice({
  name: 'currentArtist',
  initialState,
  reducers: {
    setArtist: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateArtist.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(updateArtist.fulfilled, (state, { meta, payload }) => {
        state.isSubmitting = false;

        // This should come through the `payload`, not the meta.args?
        const { avatarUrl, coverUrl, slug } = meta.arg;

        state.artist = {
          ...state.artist,
          ...payload.artist,
        };

        state.artist.slug = slug;

        if (avatarUrl) state.artist.avatarUrl = avatarUrl;
        if (coverUrl) state.artist.coverUrl = coverUrl;
      })
      .addCase(updateArtist.rejected, (state) => {
        state.isSubmitting = false;
      });
  },
});

export const { setArtist } = currentArtist.actions;

export const getArtist = (state) => state.currentArtist;

export default currentArtist.reducer;
