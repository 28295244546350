import React from 'react';

import PropTypes from 'prop-types';

import { Icon } from '..';

import navigation from '@Utils/navigation';

const BackCrumb = ({ redirect }) => {
  const handleClick = () => {
    if (redirect) {
      navigation(redirect);
    } else {
      window.history.back();
    }
  };

  return (
    <div
      role="presentation"
      className="d-flex flex-row justify-content-start align-items-center button-back"
      onClick={handleClick}
    >
      <Icon name="keyboard_arrow_left" className="back-icon" size="lg" secondary transparent />
      <p className="d-none d-lg-flex avenir-600 f-16 lh-1 back-icon">Back</p>
    </div>
  );
};

BackCrumb.propTypes = {
  redirect: PropTypes.string,
};

export default BackCrumb;
