import React from 'react';

import PropTypes from 'prop-types';

import { Icon } from '..';

const UncontrolledAccordion = ({ title, children }) => (
  <details role="presentation" className="panel p-3 prevent-select">
    <summary className="d-flex flex-row bg-gray-600 px-3 py-2 rounded-8 align-items-center">
      <span className="avenir-600 f-16 color-black flex-grow-1 text-uppercase">{title}</span>
      <Icon className="chevron-arrow" size="sm" name="keyboard_arrow_down" />
    </summary>
    <div className="w-full avenir-400 f-14 px-3 content">{children}</div>
  </details>
);

UncontrolledAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default UncontrolledAccordion;
