import { signOutRequest } from '../api/user_session';

import { signOutManagerRequest } from '../api/manager_session';

import { mainAppSignOutPath, destroyManagerSessionPath } from './routes.js.erb';

const navigation = (page, timeout) => {
  let action = () => null;

  switch (page) {
    case mainAppSignOutPath():
      action = () => signOutRequest();
      break;

    case destroyManagerSessionPath():
      action = () => signOutManagerRequest();
      break;

    default:
      action = () => window.location.assign(`${window.location.origin}${page}`);
      break;
  }

  if (timeout === undefined) {
    action();
  } else {
    setTimeout(action, timeout);
  }
};

export default navigation;
