import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioGroup = ({ children, label = null, description = null }) => {
  return (
    <div className="input-group">
      {(label !== null || description !== null) && (
        <div className="input-text">
          {label !== null && <label>{label}</label>}
          {description !== null && <p>{description}</p>}
        </div>
      )}
      <div className="d-flex flex-column gap-3 my-2">{children}</div>
    </div>
  );
};

const Radio = ({ name, value, label, onClick, disabled = false, checked }) => {
  const handleClick = (event) => {
    const data = {};
    data[event.target.name] = event.target.value;
    if (onClick) onClick(data);
  };

  const radioClasses = classNames('radio', {
    disabled,
    checked: checked === value,
  });

  return (
    <div className={radioClasses}>
      <label>
        <input
          name={name}
          type="radio"
          checked={checked === value}
          onChange={handleClick}
          value={value}
        />
        {label}
      </label>
    </div>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.string,
};

export { RadioGroup, Radio };
