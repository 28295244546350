import client from './client';

import { managerSessionPath, destroyManagerSessionPath, newManagerSessionPath } from '@Routes';

const authenticateManagerRequest = async ({ email, password, rememberMe }) => {
  await client.post(managerSessionPath(), {
    manager: {
      email,
      password,
      rememberMe,
    },
  });
};

const signOutManagerRequest = async () => {
  await client
    .delete(destroyManagerSessionPath())
    .then(() => window.location.assign(`${window.location.origin}${newManagerSessionPath()}`))
    // FIXME: Handle these errors with toasters? -> https://www.npmjs.com/package/react-toastify
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));
};

export { authenticateManagerRequest, signOutManagerRequest };
