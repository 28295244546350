import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useClickAway } from 'react-use';

const Dropdown = ({
  name,
  label = null,
  description = null,
  placeholder = 'Select option',
  data,
  selected,
  onClick,
  error = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen((state) => !state);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleSelectItem = (event) => {
    event.preventDefault();
    const dropDowndata = {};
    dropDowndata[name] = event.target.value;
    if (onClick) onClick(dropDowndata);
    handleCloseDropdown();
  };

  const displaySelected = () => {
    const display = data.filter((el) => el.value === selected);
    return display[0].title;
  };

  const dropdownArea = useRef(null);

  useClickAway(dropdownArea, () => {
    handleCloseDropdown();
  });

  const dropdownClasses = classNames('dropdown-box', {
    'dropdown-open': isOpen,
    error,
  });

  const dropdownSelectorClasses = classNames('dropdown-selector', {
    error,
  });

  return (
    <div role="presentation" className={dropdownClasses} ref={dropdownArea}>
      {(label !== null || description !== null) && (
        <div className="input-text">
          {label !== null && <label>{label}</label>}
          {description !== null && <p>{description}</p>}
        </div>
      )}
      <div role="presentation" className={dropdownSelectorClasses} onClick={handleToggleDropdown}>
        {!selected ? (
          <label htmlFor="default" className="avenir-400 truncate max-line-1">
            {placeholder}
          </label>
        ) : (
          <label className="selected avenir-400 truncate max-line-1">{displaySelected()}</label>
        )}
        <span className="material-icons">keyboard_arrow_down</span>
      </div>
      <div className="dropdown-list">
        {data.map((el) => {
          return (
            <button
              className="avenir-400 truncate max-line-1"
              value={el.value}
              key={`dropdown_${el.value}`}
              onClick={handleSelectItem}
            >
              {el.title}
            </button>
          );
        })}
      </div>
      {error && <label className="error">{error}</label>}
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
  error: PropTypes.string,
};

export default Dropdown;
