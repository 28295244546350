import React from 'react';
import PropTypes from 'prop-types';

const ProcessingLoader = ({ isLoading }) => {
  const spans = new Array(8).fill(1);

  return (
    <div className="d-flex flex-column align-items-center my-5 gap-5 loader-wrapper">
      {isLoading && (
        <div className="preloader">
          {spans.map((_el, i) => {
            return <span key={i}></span>;
          })}
        </div>
      )}
    </div>
  );
};

ProcessingLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default ProcessingLoader;
