import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from '../../Shared';

import wallet from '../../../assets/images/wallet.svg';

const InitializeAccount = ({
  onClose,
  title = 'Initialize your account',
  description = 'In order to proceed, please confirm your wallet.',
}) => (
  <Modal visible onClose={onClose}>
    <img src={wallet} alt="wallet illustration" />
    <div className="d-flex flex-column">
      <h1 className="archivo-600 f-24 text-center">{title}</h1>
      <p
        className="nunito-400 f-16 text-center mt-4"
        style={{ whiteSpace: 'pre-wrap', maxWidth: 356 }}
      >
        {description}
      </p>
    </div>
  </Modal>
);

InitializeAccount.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

// eslint-disable-next-line import/prefer-default-export
export { InitializeAccount };
