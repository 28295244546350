import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useExpanded, usePagination, useTable } from 'react-table';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import classNames from 'classnames';

import { Icon } from '..';

const Pagination = ({ instance }) => {
  const { canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage } = instance;

  const currentPage = instance.state.pageIndex;

  const goToNextPage = () => nextPage();

  const goToPreviousPage = () => previousPage();

  const goToFirstPage = () => gotoPage(0);

  const goToLastPage = () => gotoPage(pageCount - 1);

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      const pages = Array.from({ length: total }, (_, i) => i + 1);
      return pages;
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  const PaginationNumbers = () => {
    const numbers = getVisiblePages(currentPage, pageCount);

    return numbers.map((el, index) => {
      const paginationClasses = classNames('pagination-number avenir-500 f-16 small-caps', {
        'pagination-active': currentPage + 1 === el,
      });
      return (
        <div
          role="presentation"
          key={`page_${index}`}
          className={paginationClasses}
          onClick={() => {
            gotoPage(el - 1);
          }}
        >
          <span>{el}</span>
        </div>
      );
    });
  };

  return (
    <div className="pagination">
      <Icon
        name="keyboard_double_arrow_left"
        onClick={goToFirstPage}
        size="sm"
        transparent
        disabled={!canPreviousPage}
      />
      <Icon
        name="keyboard_arrow_left"
        onClick={goToPreviousPage}
        size="sm"
        transparent
        disabled={!canPreviousPage}
      />
      <div className="pages-numbers">{PaginationNumbers()}</div>
      <Icon
        name="keyboard_arrow_right"
        onClick={goToNextPage}
        size="sm"
        transparent
        disabled={!canNextPage}
      />
      <Icon
        name="keyboard_double_arrow_right"
        onClick={goToLastPage}
        size="sm"
        transparent
        disabled={!canNextPage}
      />
    </div>
  );
};

const Table = ({
  initialData,
  columns,
  pagination = true,
  rowClick,
  rowKey,
  banding = true,
  className,
  requestData,
  pageCount: initialPageCount,
  emptyState,
  params,
}) => {
  const [data, setData] = useState(initialData);
  const [pageCount, setPageCount] = useState(initialPageCount);
  const [loading, setLoading] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
      pageCount,
      initialState: {
        pageIndex: 0,
        pageSize: initialData.length,
      },
      manualPagination: pagination,
    },
    useExpanded,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, rows, gotoPage, prepareRow } =
    tableInstance;

  const tableClasses = classNames(
    {
      'table-container banded': banding,
      'table-container': !banding,
    },
    className
  );

  // toogle between rows and page according to pagination prop
  let tableData = [...rows];
  if (pagination) tableData = [...page];

  const handleRowClick = (row) => {
    if (rowClick) rowClick(row);
  };

  const fetchMore = () => {
    if (pagination) {
      const offset = tableInstance.state.pageIndex * initialData.length;
      setLoading(true);
      requestData({ offset, ...params })
        .then((response) => {
          setData(response.data);
          setPageCount(response.meta.pageCount);
          setLoading(false);
          return true;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const EmptyState = () => {
    if (emptyState) return emptyState();
    return (
      <div className="my-5 pt-3 pb-5 text-center">
        <h4 className="avenir-400 f-16 color-gray-300">No content to display yet.</h4>
      </div>
    );
  };

  useEffect(fetchMore, [
    initialData.length,
    pagination,
    params,
    requestData,
    tableInstance.state.pageIndex,
  ]);

  useEffect(() => {
    gotoPage(0);
  }, [params?.selectedFilter, gotoPage]);

  useEffect(() => {
    if (!pagination) {
      setData(initialData);
    }
  }, [initialData, pagination]);

  // FIXME: If we pass the rowKey the table will not display all the rows. We need fix the transitionGroup
  return (
    <>
      {data.length === 0 && pagination ? (
        <EmptyState />
      ) : (
        <>
          {loading ? (
            <div className="my-5 pt-3 pb-5 text-center">
              <h4 className="avenir-400 f-16 color-gray-300">Loading...</h4>
            </div>
          ) : (
            <div className="table">
              <div className={tableClasses}>
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup, i) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={`header_group_${headerGroup.headers[i].id}`}
                      >
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()} key={`header_group_th_${column.id}`}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    <TransitionGroup component={null} className={tableClasses}>
                      {tableData.map((row) => {
                        prepareRow(row);

                        if (rowKey)
                          return (
                            <CSSTransition
                              key={`row_${row.original[rowKey]}`}
                              timeout={700}
                              classNames="table-row-anim"
                            >
                              <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                                {row.cells.map((cell, i) => (
                                  <td
                                    key={`cell_row_${i}_${cell.row.id}`}
                                    {...cell.getCellProps({
                                      className: cell.column.className,
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            </CSSTransition>
                          );
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => handleRowClick(row)}
                            key={`row_${row.id}`}
                          >
                            {row.cells.map((cell, i) => (
                              <td
                                key={`cell_row_${i}_${cell.row.id}`}
                                {...cell.getCellProps({
                                  className: cell.column.className,
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </TransitionGroup>
                  </tbody>
                </table>
              </div>
              {pagination && <Pagination instance={tableInstance} />}
            </div>
          )}
        </>
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  initialData: PropTypes.array,
  pagination: PropTypes.bool,
  rowClick: PropTypes.func,
  pageSize: PropTypes.number,
  banding: PropTypes.bool,
  className: PropTypes.string,
  rowKey: PropTypes.string,
  requestData: PropTypes.func,
  pageCount: PropTypes.number,
  emptyState: PropTypes.node,
  setLoadingPosts: PropTypes.func,
  params: PropTypes.object,
};

Pagination.propTypes = {
  instance: PropTypes.object,
};

export default Table;
