import React from 'react';

const Logo = () => (
  <svg className="logo" viewBox="0 0 131 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="logo-letter letter-s"
      d="M39.8898 22.6873C38.9614 22.6873 38.0611 22.5643 37.1887 22.3182C36.3275 22.061 35.6341 21.731 35.1084 21.3284L36.0311 19.2816C36.5344 19.6507 37.1328 19.9471 37.8262 20.1708C38.5197 20.3945 39.2131 20.5063 39.9065 20.5063C40.6783 20.5063 41.2487 20.3945 41.6178 20.1708C41.9868 19.9359 42.1714 19.6284 42.1714 19.2481C42.1714 18.9685 42.0595 18.7392 41.8358 18.5602C41.6233 18.3701 41.3437 18.2191 40.997 18.1073C40.6615 17.9954 40.2029 17.8724 39.6213 17.7382C38.7266 17.5257 37.994 17.3132 37.4236 17.1007C36.8532 16.8882 36.3611 16.547 35.9472 16.0773C35.5446 15.6075 35.3433 14.9812 35.3433 14.1983C35.3433 13.516 35.5278 12.9009 35.8969 12.3529C36.266 11.7936 36.8196 11.3519 37.5578 11.0275C38.3072 10.7032 39.2187 10.541 40.2924 10.541C41.0418 10.541 41.7743 10.6305 42.4901 10.8094C43.2059 10.9884 43.8323 11.2456 44.3691 11.5811L43.5303 13.6447C42.4454 13.0295 41.3605 12.722 40.2756 12.722C39.5151 12.722 38.9503 12.845 38.5812 13.091C38.2233 13.3371 38.0443 13.6614 38.0443 14.0641C38.0443 14.4667 38.2512 14.7687 38.6651 14.97C39.0901 15.1602 39.7332 15.3503 40.5944 15.5404C41.4891 15.7529 42.2217 15.9654 42.7921 16.1779C43.3625 16.3905 43.8491 16.726 44.2517 17.1845C44.6655 17.6431 44.8724 18.2638 44.8724 19.0468C44.8724 19.7178 44.6823 20.333 44.302 20.8922C43.9329 21.4402 43.3737 21.8764 42.6244 22.2008C41.875 22.5251 40.9635 22.6873 39.8898 22.6873Z"
      fill="url(#paint0_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-p"
      d="M50.6217 13.3259C51.4606 13.3259 52.2211 13.5216 52.9033 13.9131C53.5968 14.2934 54.1392 14.8358 54.5307 15.5404C54.9221 16.2339 55.1179 17.0447 55.1179 17.9731C55.1179 18.9014 54.9221 19.7178 54.5307 20.4225C54.1392 21.1159 53.5968 21.6583 52.9033 22.0498C52.2211 22.4301 51.4606 22.6202 50.6217 22.6202C49.4697 22.6202 48.5638 22.2567 47.9039 21.5297V25.7407H45.2867V13.4601H47.7865V14.5003C48.4352 13.7174 49.3802 13.3259 50.6217 13.3259ZM50.1687 20.4728C50.8398 20.4728 51.3879 20.2491 51.8129 19.8017C52.2491 19.3431 52.4672 18.7336 52.4672 17.9731C52.4672 17.2125 52.2491 16.6086 51.8129 16.1612C51.3879 15.7026 50.8398 15.4733 50.1687 15.4733C49.4977 15.4733 48.9441 15.7026 48.5079 16.1612C48.0828 16.6086 47.8703 17.2125 47.8703 17.9731C47.8703 18.7336 48.0828 19.3431 48.5079 19.8017C48.9441 20.2491 49.4977 20.4728 50.1687 20.4728Z"
      fill="url(#paint1_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-r"
      d="M58.1113 14.6513C58.4245 14.2151 58.8439 13.8851 59.3696 13.6614C59.9064 13.4378 60.5216 13.3259 61.215 13.3259V15.7418C60.9242 15.7194 60.7285 15.7082 60.6278 15.7082C59.8785 15.7082 59.2913 15.9207 58.8663 16.3457C58.4413 16.7595 58.2288 17.3859 58.2288 18.2247V22.486H55.6116V13.4601H58.1113V14.6513Z"
      fill="url(#paint2_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-e"
      d="M70.2723 18.0066C70.2723 18.0402 70.2556 18.275 70.222 18.7112H63.3939C63.5169 19.2705 63.8077 19.7122 64.2663 20.0366C64.7248 20.3609 65.2952 20.5231 65.9775 20.5231C66.4472 20.5231 66.8611 20.456 67.219 20.3218C67.5881 20.1764 67.9292 19.9527 68.2423 19.6507L69.6348 21.1606C68.7848 22.1337 67.5433 22.6202 65.9104 22.6202C64.8926 22.6202 63.9923 22.4245 63.2093 22.033C62.4264 21.6304 61.8225 21.0767 61.3975 20.3721C60.9725 19.6675 60.7599 18.8678 60.7599 17.9731C60.7599 17.0895 60.9669 16.2954 61.3807 15.5908C61.8057 14.875 62.3817 14.3213 63.1087 13.9299C63.8469 13.5272 64.6689 13.3259 65.5749 13.3259C66.4584 13.3259 67.2581 13.516 67.9739 13.8963C68.6897 14.2766 69.249 14.8246 69.6516 15.5404C70.0654 16.2451 70.2723 17.0671 70.2723 18.0066ZM65.5916 15.3056C64.9989 15.3056 64.5011 15.4733 64.0985 15.8089C63.6959 16.1444 63.4498 16.603 63.3603 17.1845H67.8062C67.7167 16.6141 67.4706 16.1612 67.068 15.8256C66.6653 15.4789 66.1732 15.3056 65.5916 15.3056Z"
      fill="url(#paint3_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-a"
      d="M74.1513 13.3259C75.5493 13.3259 76.623 13.6614 77.3724 14.3325C78.1218 14.9924 78.4964 15.9934 78.4964 17.3355V22.486H76.047V21.3619C75.5549 22.2008 74.6378 22.6202 73.2957 22.6202C72.6022 22.6202 71.9983 22.5028 71.4838 22.2679C70.9805 22.033 70.5946 21.7087 70.3262 21.2948C70.0578 20.881 69.9236 20.4113 69.9236 19.8856C69.9236 19.0468 70.2367 18.3869 70.8631 17.9059C71.5006 17.425 72.4792 17.1845 73.799 17.1845H75.8793C75.8793 16.6141 75.7059 16.1779 75.3592 15.876C75.0125 15.5628 74.4924 15.4062 73.799 15.4062C73.318 15.4062 72.8427 15.4845 72.373 15.6411C71.9144 15.7865 71.5229 15.9878 71.1986 16.2451L70.2591 14.4164C70.7512 14.0697 71.3384 13.8013 72.0206 13.6111C72.7141 13.421 73.4243 13.3259 74.1513 13.3259ZM73.95 20.8586C74.3973 20.8586 74.7944 20.758 75.1411 20.5567C75.4878 20.3442 75.7339 20.0366 75.8793 19.6339V18.7112H74.0842C73.0105 18.7112 72.4736 19.0635 72.4736 19.7682C72.4736 20.1037 72.6022 20.3721 72.8595 20.5734C73.1279 20.7636 73.4914 20.8586 73.95 20.8586Z"
      fill="url(#paint4_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-d"
      d="M88.8988 10.0377V22.486H86.399V21.4458C85.7503 22.2287 84.8109 22.6202 83.5806 22.6202C82.7305 22.6202 81.9588 22.4301 81.2654 22.0498C80.5831 21.6695 80.0463 21.1271 79.6548 20.4225C79.2634 19.7178 79.0676 18.9014 79.0676 17.9731C79.0676 17.0447 79.2634 16.2283 79.6548 15.5237C80.0463 14.819 80.5831 14.2766 81.2654 13.8963C81.9588 13.516 82.7305 13.3259 83.5806 13.3259C84.7326 13.3259 85.6329 13.6894 86.2816 14.4164V10.0377H88.8988ZM84.0335 20.4728C84.6934 20.4728 85.2415 20.2491 85.6777 19.8017C86.1138 19.3431 86.3319 18.7336 86.3319 17.9731C86.3319 17.2125 86.1138 16.6086 85.6777 16.1612C85.2415 15.7026 84.6934 15.4733 84.0335 15.4733C83.3625 15.4733 82.8088 15.7026 82.3726 16.1612C81.9364 16.6086 81.7184 17.2125 81.7184 17.9731C81.7184 18.7336 81.9364 19.3431 82.3726 19.8017C82.8088 20.2491 83.3625 20.4728 84.0335 20.4728Z"
      fill="url(#paint5_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-m"
      d="M101.622 13.3259C102.752 13.3259 103.646 13.6614 104.306 14.3325C104.977 14.9924 105.313 15.9878 105.313 17.3188V22.486H102.696V17.7214C102.696 17.0056 102.545 16.4743 102.243 16.1276C101.952 15.7697 101.532 15.5908 100.984 15.5908C100.369 15.5908 99.8827 15.7921 99.5248 16.1947C99.1669 16.5862 98.988 17.1734 98.988 17.9563V22.486H96.3708V17.7214C96.3708 16.301 95.8004 15.5908 94.6596 15.5908C94.0556 15.5908 93.5747 15.7921 93.2168 16.1947C92.8589 16.5862 92.6799 17.1734 92.6799 17.9563V22.486H90.0628V13.4601H92.5625V14.5003C92.898 14.12 93.3063 13.8292 93.7872 13.6279C94.2793 13.4266 94.8162 13.3259 95.3978 13.3259C96.0353 13.3259 96.6113 13.4545 97.1258 13.7118C97.6403 13.9578 98.0541 14.3213 98.3672 14.8023C98.7363 14.3325 99.2005 13.969 99.7597 13.7118C100.33 13.4545 100.951 13.3259 101.622 13.3259Z"
      fill="url(#paint6_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-i"
      d="M106.4 13.4601H109.018V22.486H106.4V13.4601ZM107.709 12.2019C107.228 12.2019 106.837 12.0621 106.535 11.7825C106.233 11.5028 106.082 11.1561 106.082 10.7423C106.082 10.3285 106.233 9.98176 106.535 9.70215C106.837 9.42254 107.228 9.28273 107.709 9.28273C108.19 9.28273 108.581 9.41694 108.883 9.68537C109.185 9.9538 109.336 10.2893 109.336 10.692C109.336 11.1282 109.185 11.4917 108.883 11.7825C108.581 12.0621 108.19 12.2019 107.709 12.2019Z"
      fill="url(#paint7_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-n"
      d="M115.674 13.3259C116.793 13.3259 117.693 13.6614 118.375 14.3325C119.069 15.0036 119.415 15.999 119.415 17.3188V22.486H116.798V17.7214C116.798 17.0056 116.642 16.4743 116.329 16.1276C116.015 15.7697 115.562 15.5908 114.97 15.5908C114.31 15.5908 113.784 15.7977 113.393 16.2115C113.001 16.6141 112.805 17.2181 112.805 18.0234V22.486H110.188V13.4601H112.688V14.5171C113.035 14.1368 113.465 13.846 113.98 13.6447C114.494 13.4322 115.059 13.3259 115.674 13.3259Z"
      fill="url(#paint8_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-t"
      d="M126.351 22.0498C126.094 22.2399 125.775 22.3853 125.395 22.486C125.026 22.5755 124.634 22.6202 124.22 22.6202C123.147 22.6202 122.313 22.3462 121.721 21.7981C121.139 21.2501 120.848 20.4448 120.848 19.3823V15.6746H119.456V13.6614H120.848V11.4637H123.465V13.6614H125.714V15.6746H123.465V19.3487C123.465 19.729 123.56 20.0254 123.751 20.2379C123.952 20.4392 124.232 20.5399 124.589 20.5399C125.003 20.5399 125.356 20.428 125.646 20.2044L126.351 22.0498Z"
      fill="url(#paint9_linear_1115_37515)"
    />
    <path
      className="logo-letter letter-."
      d="M128.474 22.6202C128.016 22.6202 127.63 22.4636 127.317 22.1504C127.004 21.8373 126.847 21.4458 126.847 20.9761C126.847 20.4951 127.004 20.1093 127.317 19.8185C127.63 19.5165 128.016 19.3655 128.474 19.3655C128.933 19.3655 129.319 19.5165 129.632 19.8185C129.945 20.1093 130.102 20.4951 130.102 20.9761C130.102 21.4458 129.945 21.8373 129.632 22.1504C129.319 22.4636 128.933 22.6202 128.474 22.6202Z"
      fill="url(#paint10_linear_1115_37515)"
    />
    <path
      className="logo-rotate"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3667 17.3417C17.4315 17.3978 17.4958 17.4544 17.5596 17.5117C17.5621 17.4065 17.5635 17.3011 17.5636 17.1953L17.5636 17.179L17.5636 17.1626L17.5636 17.143L17.5206 17.1857L17.5445 17.1617L17.5636 17.1424C17.5504 12.2031 14.8619 7.89272 10.8707 5.58394C7.53468 7.51371 5.1088 10.8418 4.39453 14.7669C5.72211 14.3258 7.14199 14.087 8.61763 14.087C11.9612 14.087 15.0184 15.3129 17.3644 17.3398C17.409 17.2965 17.4534 17.2528 17.4975 17.2088C17.4535 17.2529 17.4099 17.2972 17.3667 17.3417ZM17.7605 17.6816C17.6957 17.6256 17.6314 17.5689 17.5676 17.5117C17.5651 17.6168 17.5637 17.7223 17.5636 17.828L17.5636 17.8444L17.5636 17.8607L17.5636 17.8803L17.6066 17.8377L17.5827 17.8616L17.5636 17.8809C17.5768 22.8203 20.2653 27.1306 24.2565 29.4394C27.5925 27.5096 30.0184 24.1815 30.7327 20.2565C29.4051 20.6975 27.9852 20.9363 26.5096 20.9363C23.166 20.9363 20.1088 19.7104 17.7628 17.6836C17.7182 17.7269 17.6738 17.7706 17.6297 17.8146C17.6737 17.7705 17.7173 17.7262 17.7605 17.6816Z"
      fill="#5A87FD"
    />
    <path
      className="logo-rotate"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6234 17.2746C17.6077 17.3596 17.5912 17.4444 17.574 17.529C17.6664 17.4787 17.7584 17.4272 17.85 17.3744L17.8642 17.3662L17.8783 17.3581L17.8953 17.3482L17.8366 17.3318L17.8694 17.3408L17.8958 17.3479C22.1667 14.8666 24.536 10.3497 24.5112 5.68916C21.148 3.72343 17.0353 3.25642 13.2739 4.59146C14.3292 5.53719 15.2561 6.66513 16.0046 7.96145C17.7004 10.8987 18.1893 14.1973 17.6239 17.2716C17.684 17.2892 17.7443 17.3063 17.8049 17.3231C17.7444 17.3065 17.6839 17.2903 17.6234 17.2746ZM17.5287 17.7906C17.5444 17.7056 17.5608 17.6208 17.5781 17.5361C17.4857 17.5865 17.3937 17.638 17.3021 17.6908L17.2879 17.6989L17.2738 17.7071L17.2568 17.717L17.3155 17.7333L17.2827 17.7244L17.2563 17.7172C12.9854 20.1985 10.6161 24.7155 10.6409 29.376C14.0041 31.3417 18.1167 31.8088 21.8782 30.4737C20.8229 29.528 19.896 28.4 19.1475 27.1037C17.4517 24.1665 16.9628 20.8678 17.5282 17.7935C17.4681 17.776 17.4077 17.7589 17.3472 17.7421C17.4077 17.7587 17.4682 17.7748 17.5287 17.7906Z"
      fill="#5A87FD"
      fillOpacity="0.3"
    />
    <path
      className="logo-rotate"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7344 17.3272C17.6784 17.3932 17.6217 17.4587 17.5644 17.5236C17.6696 17.5262 17.7751 17.5276 17.8808 17.5277L17.8971 17.5277L17.9135 17.5277L17.9331 17.5276L17.8905 17.4839L17.9144 17.5082L17.9337 17.5276C22.8731 17.5142 27.1834 14.7772 29.4922 10.7138C27.5624 7.31754 24.2343 4.84782 20.3093 4.12065C20.7503 5.47222 20.9891 6.91775 20.9891 8.42006C20.9891 11.824 19.7632 14.9365 17.7364 17.3249C17.7797 17.3703 17.8233 17.4155 17.8674 17.4604C17.8233 17.4156 17.779 17.3712 17.7344 17.3272ZM17.3945 17.7282C17.4505 17.6622 17.5072 17.5967 17.5644 17.5318C17.4593 17.5292 17.3538 17.5278 17.2481 17.5277L17.2318 17.5277L17.2154 17.5277L17.1958 17.5277L17.2384 17.5714L17.2145 17.5472L17.1952 17.5277C12.2558 17.5412 7.94549 20.2782 5.63671 24.3415C7.56649 27.7378 10.8946 30.2075 14.8196 30.9347C14.3786 29.5832 14.1398 28.1376 14.1398 26.6353C14.1398 23.2313 15.3657 20.1188 17.3925 17.7305C17.3492 17.6851 17.3056 17.6399 17.2615 17.595C17.3056 17.6397 17.3499 17.6841 17.3945 17.7282Z"
      fill="#5A87FD"
      fillOpacity="0.1"
    />
    <path
      className="logo-rotate"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4809 17.2676C17.5095 17.3488 17.5374 17.4303 17.5645 17.5121C17.6193 17.4223 17.6732 17.3317 17.7262 17.2402L17.7343 17.226L17.7425 17.2119L17.7523 17.1948L17.6935 17.2101L17.7263 17.2014L17.7526 17.1943C20.2107 12.9101 20.0165 7.82073 17.6829 3.80753C13.8027 3.79559 10.0187 5.45387 7.43198 8.49264C8.81266 8.78053 10.1729 9.29013 11.4624 10.0347C14.3844 11.7216 16.4432 14.3258 17.4799 17.2647C17.5405 17.2498 17.6011 17.2343 17.6617 17.2185C17.6012 17.2345 17.541 17.2508 17.4809 17.2676ZM17.6551 17.7607C17.6265 17.6794 17.5986 17.5979 17.5715 17.5162C17.5167 17.6059 17.4628 17.6966 17.4098 17.7881L17.4017 17.8023L17.3935 17.8164L17.3837 17.8334L17.4425 17.8182L17.4097 17.8269L17.3834 17.8339C14.9253 22.1182 15.1196 27.2075 17.4531 31.2207C21.3333 31.2327 25.1173 29.5744 27.704 26.5356C26.3233 26.2477 24.9631 25.7381 23.6736 24.9936C20.7516 23.3066 18.6929 20.7025 17.6561 17.7635C17.5955 17.7785 17.5349 17.7939 17.4743 17.8098C17.5348 17.7938 17.5951 17.7774 17.6551 17.7607Z"
      fill="#5A87FD"
      fillOpacity="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1115_37515"
        x1="36.1952"
        y1="17.5117"
        x2="131.134"
        y2="17.5117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A87FD" />
        <stop offset="0.75" stopColor="#61C4CB" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
