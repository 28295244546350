import client from './client';

import {
  mainAppUsersPath,
  mainAppUserPath,
  mainAppShippingDetailsPath,
} from '@Utils/routes.js.erb';

const mapFormDataToParams = ({
  avatarUrl,
  username,
  email,
  firstName,
  lastName,
  address,
  imageUploadedIsDifferent,
}) => {
  const params = {
    email,
    username,
    firstName,
    lastName,
    addressAttributes: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      country: address.country,
      city: address.city,
      zipCode: address.zipCode,
    },
  };

  if (imageUploadedIsDifferent) params.avatar = { data: avatarUrl };

  return params;
};

export const fetchUserRequest = async (blockchainAddress) => {
  return client.get(mainAppUserPath(blockchainAddress));
};

export const createUserRequest = async (blockchainAddress) => {
  return client.post(mainAppUsersPath(), { blockchainAddress });
};

export const updateUserRequest = async (id, payload) => {
  const params = mapFormDataToParams(payload);

  return client.patch(mainAppUserPath(id), {
    user: params,
  });
};

export const deleteUserRequest = async (id) => client.delete(mainAppUserPath(id));

export const deleteUserShippingDetailsRequest = async (id) =>
  client.delete(mainAppShippingDetailsPath(id));
