import React from 'react';

import PropTypes from 'prop-types';

import { Button, Icon } from '@SharedComponents/';

import { mainAppMarketplacePath, mainAppArtistPath } from '@Utils/routes.js.erb';

import navigation from '@Utils/navigation';

import { PostShape } from '@Utils/propsShape';

const BlockedPost = ({ post: { content, artist, tiers, canMint }, mint }) => {
  const goToMarketplace = () => {
    const tierIds = tiers.map((tier) => {
      return tier.id;
    });

    navigation(mainAppMarketplacePath({ artists: [artist.id], tiers: tierIds }));
  };

  const mintArtistCollections = () => {
    if (!window.location.pathname.includes('/artists/')) {
      navigation(mainAppArtistPath({ slug: artist.slug, minting: true }));
    } else {
      mint();
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center w-100 h-100 gap-4 p-5 post-tier-blocked">
      <Icon
        as="span"
        name="visibility_off"
        size="xl"
        className="post-blocked-icon color-dark"
        transparent
      />
      <h3 className="avenir-600 f-18 color-dark my-4 small-caps">Want to unlock this content?</h3>
      <p className="avenir-400 f-16 color-dark">{content}</p>
      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3 mt-5">
        <Button label="Mint now" disabled={!canMint} onClick={mintArtistCollections} />
        <Button label="Marketplace" secondary onClick={goToMarketplace} />
      </div>
    </div>
  );
};

BlockedPost.propTypes = {
  post: PostShape,
  mint: PropTypes.func,
};

export default BlockedPost;
