import React, { useId, forwardRef } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

const Checkbox = forwardRef(
  ({ label, disabled = false, checked = false, className, onClick, count, ...rest }, ref) => {
    const handleClick = (event) => {
      const data = {};
      data[event.target.name] = event.target.checked;
      if (onClick) onClick(data);
    };

    const id = `checkbox_${useId()}`;

    const checkboxClasses = classNames('checkbox', { disabled }, className);

    return (
      <div className={checkboxClasses}>
        <div className="checkbox-container">
          <input
            id={id}
            ref={ref}
            type="checkbox"
            onChange={handleClick}
            checked={checked}
            {...rest}
          />
          {checked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 22 18"
              fill="none"
            >
              <path
                d="M1 8L5.5 12.5L17 1"
                className="check-path"
                stroke="white"
                strokeWidth="3px"
              />
            </svg>
          )}
        </div>
        <div className="d-flex flex-row gap-2 justify-items-center w-100">
          <label htmlFor={id} className="truncate max-line-1">
            {label}
          </label>
          {count >= 0 && (
            <span className="d-lg-flex flex-grow-1 align-self-center avenir-500 f-14 ps-2 color-primary-blue">
              ({count})
            </span>
          )}
        </div>
      </div>
    );
  }
);

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  checked: PropTypes.bool,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

export default Checkbox;
