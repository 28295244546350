import client from './client';

import { mainAppOperationsPath } from '@Routes';

const mapParams = ({ tokenId, selectedFilter, bidsOffers, offset }) => {
  const params = {};
  if (tokenId) params.token_id = tokenId;
  if (selectedFilter && selectedFilter.length > 0) params.filter = selectedFilter;
  if (bidsOffers) params.bidsOffers = bidsOffers;
  if (offset) params.offset = offset;
  return params;
};

export const fetchOperationsRequest = async ({ tokenId, selectedFilter, bidsOffers, offset }) => {
  const params = mapParams({ tokenId, selectedFilter, bidsOffers, offset });
  return client.get(mainAppOperationsPath(params));
};

export default fetchOperationsRequest;
