import React from 'react';

import PropTypes from 'prop-types';

import ethLogo from '@Images/ethLogo.svg';

import { calculateDaysLeft } from '@Utils/date';

import { TokenListingShape } from '@Utils/propsShape';

const BidCardLayout = ({ listing, topBidAmount }) =>
  topBidAmount ? (
    <div className="d-flex flex-row token-price">
      <div className="d-flex flex-column">
        <p className="avenir-600 f-16 small-caps color-gray-300">Top Bid</p>
        <p className="avenir-400 f-14 color-gray-300">{calculateDaysLeft(listing.expiresAt)}</p>
      </div>
      <div className="d-flex flex-row align-items-center gap-2">
        <img className="token-price-image" src={ethLogo} alt="ethereum" width="25" height="25" />
        <p className="avenir-700 ffff-16">{topBidAmount}</p>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-row token-price">
      <div className="d-flex flex-column">
        <p className="avenir-600 f-16 small-caps color-gray-300">Minimum Bid</p>
        <p className="avenir-400 f-14 color-gray-300">{calculateDaysLeft(listing.expiresAt)}</p>
      </div>
      <div className="d-flex flex-row align-items-center gap-2">
        <img className="token-price-image" src={ethLogo} alt="ethereum" width="25" height="25" />
        <p className="avenir-700 f-16">{listing.minimumBid}</p>
      </div>
    </div>
  );

BidCardLayout.propTypes = {
  listing: TokenListingShape,
  topBidAmount: PropTypes.number,
};

export default BidCardLayout;
