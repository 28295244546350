import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Subscription } from '..';

import { Button, CollapsePanel, Icon } from '@SharedComponents/';

import navigation from '@Utils/navigation';

import { mainAppMarketplacePath } from '@Routes';

const Subscriptions = ({ tiers: subscriptions }) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [showAllSubs, setShowAllSubs] = useState(!subscriptions.length > 3);

  const goToMarketplace = () => navigation(mainAppMarketplacePath());

  const renderSubscriptions = () =>
    subscriptions.length === 0 ? (
      <div className="d-flex flex-column align-items-center text-center py-5 px-5 py-lg-4 px-lg-0">
        <h5 className="avenir-400 color-gray-300 mb-4 pb-2 f-16">No subscriptions yet.</h5>
        <h5 className="avenir-400 color-gray-300 mb-4 f-16">
          Buy a token to automatically subscribe to a tier.
        </h5>
        <Button label="Explore" onClick={goToMarketplace} />
      </div>
    ) : (
      <ul>
        {subscriptions.map((subscription, i) => {
          if (showAllSubs || i < 2)
            return <Subscription subscription={subscription} key={subscription.id} />;
          return <React.Fragment key={subscription.id}></React.Fragment>;
        })}
      </ul>
    );

  const renderBottomContent = () => {
    if (!showAllSubs && subscriptions.length > 0 && subscriptions.length < 3) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 mt-lg-3 mb-3 pb-5">
          <p className="avenir-400 color-gray-300 f-16 mb-2">Add more tiers</p>
          <Button label="Explore" onClick={goToMarketplace} />
        </div>
      );
    }
    return (
      <div
        role="presentation"
        className="d-flex flex-row justify-content-center align-items-center mt-4 mt-lg-2 pb-4 pb-lg-4 cursor-pointer"
        onClick={() => setShowAllSubs((state) => !state)}
      >
        <p className="avenir-400 f-16 color-gray-300">
          {showAllSubs ? 'Show less' : `Show more (${subscriptions.length - 2})`}
        </p>
        <Icon
          name={`keyboard_arrow_${showAllSubs ? 'up' : 'down'}`}
          className="color-primary-blue"
        />
      </div>
    );
  };

  return (
    <div className="mysubs-wrapper">
      <h6 className="avenir-500 f-18 f-xs-16 f-sm-16 my-0 mb-lg-4 d-none d-lg-block text-uppercase small-caps">
        My Subscriptions
        {subscriptions.length > 0 && (
          <span className="color-primary-blue px-1">({subscriptions.length})</span>
        )}
      </h6>
      <div className="w-100 mt-5 mt-lg-0 panel">
        <CollapsePanel
          id="panel-subscriptions"
          open={panelOpen}
          openCallback={setPanelOpen}
          title={'My Subscriptions'}
          length={subscriptions.length}
          bottomContent={subscriptions.length > 0 && renderBottomContent()}
        >
          {renderSubscriptions()}
        </CollapsePanel>
      </div>
    </div>
  );
};

Subscriptions.propTypes = {
  tiers: PropTypes.array.isRequired,
};

export default Subscriptions;
