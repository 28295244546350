import { useState, useCallback } from 'react';

import { useMount } from 'react-use';

const useScrollItemsPagination = ({
  initialItems,
  initialMeta,
  req,
  params,
  keyExtractor,
  onSuccess,
  onMount = true,
}) => {
  const [items, setItems] = useState(initialItems ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState(initialMeta);
  const [errors, setErrors] = useState();

  const handleSuccess = useCallback(
    (response) => {
      setItems((prev) => {
        if (keyExtractor) return [...prev, ...response[keyExtractor]];
        return [...prev, response];
      });
      if (response?.meta) setMeta(response?.meta);
      setIsLoading(false);
      if (onSuccess) return onSuccess(response);
      return response;
    },
    [keyExtractor, onSuccess]
  );

  const handleErrors = useCallback((err) => {
    setErrors(err);
    setIsLoading(false);
    return err;
  }, []);

  const resetItems = async () => {
    if (!isLoading) {
      setIsLoading(true);
      setItems([]);
      req({
        ...params,
        offset: 0,
      })
        .then(handleSuccess)
        .catch(handleErrors);
    }
    return null;
  };

  const loadMoreItems = async () => {
    if (!isLoading && meta.nextPage) {
      setIsLoading(true);
      req({
        ...params,
        offset: items.length,
      })
        .then(handleSuccess)
        .catch(handleErrors);
    }
    return null;
  };

  useMount(() => onMount && loadMoreItems());

  return {
    items,
    setItems,
    hasNextPage: meta.nextPage ?? false,
    isLoading,
    loadMoreItems,
    resetItems,
    meta,
    errors,
  };
};

export default useScrollItemsPagination;
