import React, { forwardRef, useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

/* 
 - @mariadriana_deemaze

Only way I found to access the value prop was force passing it via props. 
Tried accesing the value via ref, rest, experimented with useEffect and all to no avail.
If anyone finds a better way to access it, please ping me. 
*/

const InputWithPersistentString = forwardRef(
  ({ label = null, description = null, passInputValue, persistentString, error, ...rest }, ref) => {
    const [focused, setIsFocused] = useState(false);

    const inputWrapperClasses = classNames(
      'input-group-persist-string avenir-400 f-16',
      {
        error,
      },
      rest.className
    );

    const inputGroupClasses = classNames('input', {
      error,
    });

    const inputClasses = classNames({
      error,
    });

    const passedInputValue = passInputValue();

    const hasValue = passedInputValue.length > 0;

    return (
      <div role="presentation" className={inputWrapperClasses}>
        {(label !== null || description !== null) && (
          <div className="input-text">
            {label !== null && <label>{label}</label>}
            {description !== null && <p>{description}</p>}
          </div>
        )}
        <div className={inputGroupClasses}>
          {focused ? (
            <input
              className={inputClasses}
              type="text"
              ref={ref}
              {...rest}
              onBlur={(e) => {
                rest.onBlur(e);
                setIsFocused(false);
              }}
            />
          ) : (
            <div role="presentation" className="display" onClick={() => setIsFocused(true)}>
              {hasValue ? (
                <>
                  <span>{persistentString}</span>
                  {passedInputValue}
                </>
              ) : (
                rest?.placeholder
              )}
            </div>
          )}

          {error && (
            <>
              <span className="material-icons input-icon error-icon">error_outline</span>
              <label className="error">{error}</label>
            </>
          )}
        </div>
      </div>
    );
  }
);

InputWithPersistentString.displayName = 'InputWithPersistentString';

InputWithPersistentString.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  passInputValue: PropTypes.func.isRequired,
  persistentString: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default InputWithPersistentString;
