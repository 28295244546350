import React from 'react';

import Image from '@SharedComponents/Image';

import navigation from '@Utils/navigation';

import { TierShape } from '@Utils/propsShape';

import { mainAppMarketplacePath, mainAppArtistPath } from '@Routes';

const Subscription = ({ subscription: { artist, title, imageUrl, id } }) => {
  const goToMarketplace = () =>
    navigation(mainAppMarketplacePath({ artists: [artist.id], tiers: [id] }));

  return (
    <li role="presentation" className="d-flex flex-column" onClick={goToMarketplace}>
      <a href={mainAppArtistPath({ slug: artist.slug, filter: [title] })}>
        <div className="tier">
          <Image src={imageUrl} alt={`image_tier_${title}`} className="tier-image" />
          <div className="tier-info">
            <h6 className="avenir-600 f-14 f-xs-12 f-sm-12 color-primary-blue text-uppercase small-caps">{`By ${artist.name}`}</h6>
            <h5 className="avenir-600 f-16 text-uppercase mt-1 color-black">{title}</h5>
          </div>
        </div>
      </a>
      <hr className="d-block g-col-12" />
    </li>
  );
};

Subscription.propTypes = {
  subscription: TierShape,
};

export default Subscription;
