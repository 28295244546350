import { isPast, differenceInDays } from 'date-fns';

import { pluralize } from './format';

export const dateFormats = {
  // 02/02/2023
  AbbreviatedDate: 1,
  // 02/02/2023 15:13
  AbbreviatedDateTime: 2,
  // Monday, 1st January 2023, 15:13
  UnabbreviatedDateTime: 3,
};

export const hasExpired = (date) => {
  const expiresAt = new Date(date);
  return isPast(expiresAt);
};

export const calculateDaysLeft = (date) => {
  const daysLeft = differenceInDays(new Date(date), new Date());

  const dayString = pluralize(Math.abs(daysLeft), 'day');

  if (Math.sign(daysLeft) === 1) {
    return `${Math.abs(daysLeft)} ${dayString} left`;
  }

  if (Math.sign(daysLeft) === -1) {
    return `Exp. ${Math.abs(daysLeft)} ${dayString} ago`;
  }

  return `Error`;
};

export const currentYear = () => new Date().getFullYear();

export const formatDate = (string, format) => {
  const date = new Date(string);

  switch (format) {
    case dateFormats.AbbreviatedDate:
      return date.toLocaleDateString();

    case dateFormats.AbbreviatedDateTime:
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`;

    case dateFormats.UnabbreviatedDateTime:
      return `${date.toLocaleDateString([], {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

    default:
      return `Error`;
  }
};
