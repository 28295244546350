import React from 'react';
import { useKey } from 'react-use';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const Modal = ({ children, visible = false, onClose, closeIcon = false, ...rest }) => {
  const modalBackdropClasses = classNames('modal-backdrop', {
    'modal-hidden': !visible,
  });

  const modalContainerClasses = classNames('modal', {
    'modal-bounce-in': visible,
    'modal-bounce-out': !visible,
  });

  const modalWrapperClasses = classNames(
    'p-5 d-flex flex-column justify-content-center align-items-center w-100',
    rest.className
  );

  useKey('Escape', () => visible && onClose && onClose());

  return (
    <div className={modalBackdropClasses}>
      <div className={modalContainerClasses} id={rest.id}>
        <div className={modalWrapperClasses}>
          {children}
          {onClose && closeIcon && (
            <button className="close" onClick={onClose}>
              <div className="line" />
              <div className="line" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  closeIcon: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Modal;
