import PropTypes from 'prop-types';

export const UserShape = PropTypes.shape({
  id: PropTypes.string,
  nonce: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.shape({
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    city: PropTypes.string,
    zip_code: PropTypes.string,
    country: PropTypes.string,
  }),
  avatarUrl: PropTypes.string,
  blockchainAddress: PropTypes.string,
});

export const SocialMediasShape = PropTypes.shape({
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  website: PropTypes.string,
});

export const ArtistShape = PropTypes.shape({
  errors: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  slug: PropTypes.string,
  tokensCount: PropTypes.number,
  socialLinks: SocialMediasShape,
  description: PropTypes.string,
});

export const TierShape = PropTypes.shape({
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  mintedCount: PropTypes.number,
  totalCount: PropTypes.number,
  benefits: PropTypes.arrayOf(PropTypes.string),
  artist: ArtistShape,
  errors: PropTypes.any,
});

export const MetaTagsShape = PropTypes.shape({
  description: PropTypes.string,
  keywords: PropTypes.array,
  og: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.string,
    site_name: PropTypes.string,
    title: PropTypes.string,
  }),
  twitter: PropTypes.shape({
    card: PropTypes.string,
  }),
});

export const PostShape = PropTypes.shape({
  id: PropTypes.string,
  artist: ArtistShape,
  createdAt: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  mediaFilesUrls: PropTypes.array,
  tiers: PropTypes.array,
  tierBlocked: PropTypes.bool,
  canMint: PropTypes.bool,
  errors: PropTypes.any,
});

export const MetaShape = PropTypes.shape({
  nextPage: PropTypes.bool,
  pageCount: PropTypes.number,
  tokensCount: PropTypes.number,
});

export const TokenListingShape = PropTypes.shape({
  price: PropTypes.string,
});

export const TokenShape = PropTypes.shape({
  id: PropTypes.string,
  tokenId: PropTypes.number,
  imageUrl: PropTypes.string,
  owner: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
  }),
  artist: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    socialLinks: PropTypes.shape(),
  }),
  collection: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  listing: TokenListingShape,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      rarity: PropTypes.number,
    })
  ),
  tier: TierShape,
});

export const PropertyShape = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
  rarity: PropTypes.number,
});

export const SVGIconsShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
});

export const SocialLinksShape = PropTypes.shape({
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  instagram: PropTypes.string,
});

export const ManagerShape = PropTypes.shape({
  errors: PropTypes.any,
  firstName: PropTypes.string,
  avatarUrl: PropTypes.string,
  hasAvatar: PropTypes.bool,
});

export const ReactHookErrorType = PropTypes.shape({
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  types: PropTypes.string,
  message: PropTypes.string,
});

export const RailsContextShape = PropTypes.shape({
  railsEnv: PropTypes.string,
  inMailer: PropTypes.bool,
  i18nLocale: PropTypes.string,
  i18nDefaultLocale: PropTypes.string,
  rorVersion: PropTypes.string,
  rorPro: PropTypes.bool,
  href: PropTypes.string,
  location: PropTypes.string,
  scheme: PropTypes.string,
  host: PropTypes.string,
  port: PropTypes.number,
  pathname: PropTypes.string,
  search: PropTypes.string,
  httpAcceptLanguage: PropTypes.string,
  serverSide: PropTypes.bool,
  honeybadgerConfig: PropTypes.shape({
    apiKey: PropTypes.string,
    environment: PropTypes.string,
    projectRoot: PropTypes.string,
    revision: PropTypes.string,
  }),
});

export const OfferShape = PropTypes.shape({
  amount: PropTypes.number,
  buyer: PropTypes.shape(UserShape),
  createdAt: PropTypes.dateTime,
  errors: PropTypes.string,
  expiresAt: PropTypes.dateTime,
  id: PropTypes.string,
  seller: PropTypes.shape(UserShape),
  status: PropTypes.string,
  token: PropTypes.shape(TokenShape),
  type: PropTypes.string,
  usdPrice: PropTypes.float,
});

export const BidShape = PropTypes.shape({
  amount: PropTypes.float,
  buyer: PropTypes.shape(UserShape),
  createdAt: PropTypes.dateTime,
  errors: PropTypes.string,
  expiresAt: PropTypes.dateTime,
  id: PropTypes.string,
  seller: PropTypes.shape(UserShape),
  status: PropTypes.string,
  token: PropTypes.shape(TokenShape),
  type: PropTypes.string,
});

export const SaleShape = PropTypes.shape({
  amount: PropTypes.float,
  buyer: PropTypes.shape(UserShape),
  createdAt: PropTypes.dateTime,
  errors: PropTypes.string,
  expiresAt: PropTypes.dateTime,
  id: PropTypes.string,
  seller: PropTypes.shape(UserShape),
  status: PropTypes.string,
  token: PropTypes.shape(TokenShape),
  type: PropTypes.string,
});

export const TransferShape = PropTypes.shape({
  amount: PropTypes.float,
  buyer: PropTypes.shape(UserShape),
  createdAt: PropTypes.dateTime,
  errors: PropTypes.string,
  expiresAt: PropTypes.dateTime,
  id: PropTypes.string,
  seller: PropTypes.shape(UserShape),
  status: PropTypes.string,
  token: PropTypes.shape(TokenShape),
  type: PropTypes.string,
});

export const RewardShape = PropTypes.shape({
  artist: PropTypes.shape(ArtistShape),
  content: PropTypes.string,
  createdAt: PropTypes.dateTime,
  errors: PropTypes.string,
  id: PropTypes.string,
  tiers: PropTypes.shape(TierShape),
  title: PropTypes.string,
  type: PropTypes.string,
});

export const FilterShape = PropTypes.shape({
  title: PropTypes.string,
  value: PropTypes.string,
});

export const RewardedUsersShape = PropTypes.shape({
  user: PropTypes.shape(UserShape),
  tier: PropTypes.shape(TierShape),
  token: PropTypes.shape(TokenShape),
});

export const FaqShape = PropTypes.shape({
  id: PropTypes.string,
  position: PropTypes.number,
  question: PropTypes.string,
  anwers: PropTypes.string,
});

export const ConversionRateShape = PropTypes.shape({
  id: PropTypes.string,
  symbol: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  createdAt: PropTypes.string,
});

// BUNDLES

export const ArtistBundleProps = {
  railsContext: RailsContextShape,
  user: UserShape.isRequired,
  artist: ArtistShape.isRequired,
  tiers: PropTypes.arrayOf(TierShape),
  posts: PropTypes.arrayOf(PostShape),
  children: PropTypes.node.isRequired,
  hasThirdWebContext: PropTypes.bool,
  meta: MetaShape.isRequired,
};

export const MarketplaceBundleProps = PropTypes.shape({
  user: UserShape,
  tokens: PropTypes.arrayOf(TokenShape),
  artists: PropTypes.arrayOf(ArtistShape),
  properties: PropTypes.arrayOf(PropertyShape),
  tiers: PropTypes.arrayOf(TierShape),
  meta: MetaShape.isRequired,
});

export const MarketplaceSearchResultsBundleProps = PropTypes.shape({
  user: UserShape,
  query: PropTypes.string,
  artists: PropTypes.arrayOf(ArtistShape),
  tokens: PropTypes.arrayOf(TokenShape),
  meta: PropTypes.shape({
    MetaShape,
    fixedPriceTokensCount: PropTypes.number,
    highestBidTokensCount: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
  railsContext: RailsContextShape,
  hasThirdWebContext: PropTypes.bool,
});

export const FaqsBundleProps = {
  faqs: PropTypes.arrayOf(FaqShape).isRequired,
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      ArtistShape,
      subscribers: PropTypes.number,
    })
  ),
};
