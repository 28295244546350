import React, { useState } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from '@SharedComponents/';

import { addFavoriteToken, removeFavoriteToken } from '@Api/favorite_tokens';

const FavoriteIcon = ({
  className,
  tokenId,
  background = false,
  favorite = false,
  favoriteCallback,
}) => {
  const [isFavorite, setIsFavorite] = useState(favorite);

  const markAsFavorite = async () => {
    if (isFavorite) {
      await removeFavoriteToken(tokenId);
    } else {
      await addFavoriteToken(tokenId);
    }

    if (favoriteCallback) favoriteCallback(tokenId, isFavorite);

    setIsFavorite((state) => !state);
  };

  const favoriteClasses = classNames('favorite-icon', {
    'show-favorite': isFavorite && !background,
    'hide-favorite': !isFavorite && !background,
    'show-favorite-background': isFavorite && background,
    'hide-favorite-background': !isFavorite && background,
    'favorite-icon-background': background,
  });

  return (
    <div role="presentation" onClick={markAsFavorite} className={favoriteClasses}>
      <Icon name="favorite_outline" className={`unfavorite ${className}`} primary={background} />
      <Icon name="favorite" className={`favorite ${className}`} primary={background} />
    </div>
  );
};

FavoriteIcon.propTypes = {
  className: PropTypes.string,
  tokenId: PropTypes.string,
  background: PropTypes.bool,
  favorite: PropTypes.bool,
  favoriteCallback: PropTypes.func,
};

export default FavoriteIcon;
