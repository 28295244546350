import React from 'react';

import { SVGIconsShape } from '@Utils/propsShape';

const LinkIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...{ width, height }} viewBox="0 0 27 27" fill="none">
    <rect width="27" height="27" rx="2" fill="currentColor" />
    <path
      d="M8.24281 18.6979C7.03365 17.4887 7.03365 15.523 8.24281 14.3138L11.0712 11.4854L9.72773 10.1419L6.8993 12.9703C4.94769 14.9219 4.94769 18.0898 6.8993 20.0414C8.85092 21.993 12.0188 21.993 13.9704 20.0414L16.7988 17.213L15.4553 15.8695L12.6269 18.6979C11.4177 19.907 9.45196 19.907 8.24281 18.6979ZM11.8491 16.5059L17.5059 10.849L16.0917 9.43479L10.4348 15.0916L11.8491 16.5059ZM13.9704 5.89926L11.1419 8.72768L12.4854 10.0712L15.3139 7.24276C16.523 6.03361 18.4888 6.03361 19.6979 7.24276C20.9071 8.45191 20.9071 10.4177 19.6979 11.6268L16.8695 14.4553L18.213 15.7988L21.0414 12.9703C22.9931 11.0187 22.9931 7.85087 21.0414 5.89926C19.0898 3.94764 15.922 3.94764 13.9704 5.89926Z"
      fill="white"
    />
  </svg>
);

LinkIcon.propTypes = SVGIconsShape;

export default LinkIcon;
