import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Icon } from '..';

const TYPES = ['warning', 'info'];

const Banner = ({ type, content, className }) => {
  const isOneOf = TYPES.includes(type);

  const wrapper = classNames(
    `w-100 d-flex flex-row align-items-center px-3 py-1 banner banner-${type}`,
    className
  );

  if (!isOneOf || (!type && !content)) return <></>;

  return (
    <div className={wrapper}>
      <Icon className={`icon-${type}`} name={type} size="md" />
      <p className="avenir-500 f-12 lh-1">{content}</p>
    </div>
  );
};

Banner.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Banner;
