import React from 'react';

import PropTypes from 'prop-types';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Avatar } from '@SharedComponents/';

import { mainAppCollectionTokenPath } from '@Routes';

import 'react-loading-skeleton/dist/skeleton.css';

import { ArtistShape } from '@Utils/propsShape';

const TokenRow = ({ artist, name, imageUrl, collectionBlockchainAddress, loading, tokenId }) => (
  <a
    href={mainAppCollectionTokenPath(collectionBlockchainAddress, tokenId)}
    className="section-row d-flex flex-row gap-4"
  >
    <div className="avatar-placement">
      <Avatar alt={`${artist.name}-avatar`} src={imageUrl} className="avatar-3" />
    </div>
    <div className="d-flex flex-row justify-between w-100">
      <div className="d-flex flex-column justify-between w-100 gap-2">
        {loading ? (
          <SkeletonTheme baseColor="#AAAAAA" highlightColor="#e0e0e0">
            <Skeleton />
            <Skeleton width="80%" />
          </SkeletonTheme>
        ) : (
          <>
            <p className="f-16 avenir-500 color-black truncate max-line-1">{name}</p>
            <p className="f-12 avenir-500 color-gray-200 truncate max-line-1">by {artist.name}</p>
          </>
        )}
      </div>
    </div>
  </a>
);

TokenRow.propTypes = {
  artist: ArtistShape,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  collectionBlockchainAddress: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default TokenRow;
