import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = forwardRef(
  (
    {
      label = null,
      description = null,
      icon = null,
      type = 'text',
      error = null,
      errorLabelOverflow = false,
      ...rest
    },
    ref
  ) => {
    const [secureTextEntry, setSecureTextEntry] = useState(type === 'password');

    const handleChangeSecureEntry = () => {
      setSecureTextEntry((state) => !state);
    };

    const handleInputType = () => {
      if (type === 'password') {
        if (secureTextEntry) {
          return 'password';
        }
        return 'text';
      }
      return type;
    };

    const inputWrapperClasses = classNames(
      'input-group avenir-400 f-16',
      {
        error,
      },
      rest.className
    );

    const inputGroupClasses = classNames('input', {
      error,
    });

    const inputClasses = classNames({
      error,
      'input-side-icon': icon,
    });

    const inputLabelError = classNames({
      error,
      'label-overflow-container': errorLabelOverflow,
    });

    return (
      <div className={inputWrapperClasses}>
        {(label !== null || description !== null) && (
          <div className="input-text">
            {label !== null && <label>{label}</label>}
            {description !== null && <p>{description}</p>}
          </div>
        )}
        <div className={inputGroupClasses}>
          <input className={inputClasses} type={handleInputType()} ref={ref} {...rest} />

          {error && type !== 'password' && (
            <span className="material-icons input-icon error-icon">error_outline</span>
          )}

          {icon && <span className="material-icons input-icon right-side-icon">{icon}</span>}

          {type === 'password' && (
            <span
              role="presentation"
              className="material-icons-outlined input-icon password-icon"
              onClick={handleChangeSecureEntry}
            >
              {secureTextEntry ? 'visibility_on' : 'visibility_off'}
            </span>
          )}
          {error && <label className={inputLabelError}>{error}</label>}
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  errorLabelOverflow: PropTypes.bool,
};

export default Input;
