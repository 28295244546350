import React, { useEffect } from 'react';

import { ThirdwebProvider } from '@thirdweb-dev/react';

import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import { ethers } from 'ethers';

import { Navbar, Footer } from '..';

import { RailsContextShape } from '@Utils/propsShape';

import { getChain } from '@Utils/web3';

import StoreProvider from '@MainAppComponents/Store/StoreProvider';

import './style.scss';

import { signOutRequest } from '@Api/user_session';

const performSignOut = async () => signOutRequest();

const signOutMetaMaskSession = (provider) => {
  if (provider) {
    provider.on('accountsChanged', () => {
      performSignOut();
    });
    provider.on('chainChanged', () => {
      performSignOut();
    });
  }
};

const PageWrapper = ({ user, children, railsContext, footer = false, hasThirdWebContext }) => {
  const env = railsContext.railsEnv;
  const provider = window?.ethereum;

  const honeybadger = Honeybadger.configure(railsContext.honeybadgerConfig);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line promise/catch-or-return, import/no-extraneous-dependencies
    import('@axe-core/react').then((axe) => {
      return axe.default(React, ReactDOM, 1000);
    });
  }

  useEffect(() => {
    signOutMetaMaskSession(provider);
  });

  if (hasThirdWebContext) {
    const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
    const chain = getChain(env);
    signOutMetaMaskSession(provider);

    return (
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <StoreProvider>
          <ThirdwebProvider
            activeChain={chain}
            provider={window.ethereum}
            autoConnect={true}
            signer={signer}
          >
            <Navbar user={user} />
            {children}
            {footer && <Footer />}
          </ThirdwebProvider>
        </StoreProvider>
      </HoneybadgerErrorBoundary>
    );
  }

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <StoreProvider>
        <Navbar user={user} />
        {children}
        {footer && <Footer />}
      </StoreProvider>
    </HoneybadgerErrorBoundary>
  );
};

PageWrapper.propTypes = {
  user: PropTypes.shape(),
  footer: PropTypes.bool,
  children: PropTypes.node.isRequired,
  railsContext: RailsContextShape,
  hasThirdWebContext: PropTypes.bool,
};

export default PageWrapper;
