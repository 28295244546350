// General UI helpers
export const KEY_ESCAPE_CODE = 27;

export const VISIBILITY_FILTERS = {
  all_posts: { value: 'all_posts', title: 'All posts' },
  visible_only: { value: 'visible_only', title: 'Visible only' },
};

export const MARKETPLACE_SORT_BY = {
  select_recently_listed: {
    value: 'recently_listed',
    title: 'Recently listed',
  },
  select_ascending_price: {
    value: 'ascending_price',
    title: 'Price: Ascending',
  },
  select_descending_price: {
    value: 'descending_price',
    title: 'Price: Descending',
  },
  select_most_viewed: {
    value: 'most_viewed',
    title: 'Most Viewed',
  },
  select_most_favorited: {
    value: 'most_favorited',
    title: 'Most Favorited',
  },
};

export const LIST_TOKEN_EXPIRATION_DATE = {
  five_days: { value: '5', title: '5 days' },
  ten_days: { value: '10', title: '10 days' },
  fifteen_days: { value: '15', title: '15 days' },
  thirty_days: { value: '30', title: '30 days' },
};

export const RECEIVER_FEES = ['Artist', 'Spreadmint', 'Owner'];

export const TRANSACTION_STATUS = {
  IDLE: 'IDLE',
  INIT: 'INIT',
  CREATED: 'CREATED',
  CONFIRMED: 'CONFIRMED',
  NEED_INFO: 'NEED_INFO',
  CANCEL: 'CANCEL',
  CANCELED: 'CANCELED',
  CONFIRMED_CANCEL: 'CONFIRMED_CANCEL',
  CONFIRMED_UPDATE: 'CONFIRMED_UPDATE',
  ERROR: 'ERROR',
};

export const DATE_OPTIONS = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const SOCIAL_LINKS_FIELDS = ['facebook', 'instagram', 'linkedin', 'twitter', 'website'];

export const HISTORY_FILTER_BY = {
  default: { value: '', title: 'All' },
  sale: { value: 'sale', title: 'Sale' },
  offer: { value: 'offer', title: 'Offer' },
  bid: { value: 'bid', title: 'Bid' },
  transaction: { value: 'transaction', title: 'Transaction' },
};

export const CREATE_POST_ACCESS = {
  PUBLIC: 'Public',
  SPECIFIC: 'Specific',
};

export const REWARD_TYPES = {
  PHYSICAL: 'Physical',
  DIGITAL: 'Digital',
};

export const REWARDS_TYPE_FILTERS = {
  all_rewards: { value: 'all', title: 'All' },
  digital_reward: { value: 'DigitalReward', title: 'Digital' },
  physical_reward: { value: 'PhysicalReward', title: 'Physical' },
};

export const LISTING_TYPES = [
  {
    value: 'HighestBid',
    title: 'On auction',
  },
  { value: 'FixedPrice', title: 'On sale' },
];

export const FILTERS_DEBOUNCE_VALUE = 1000;

// Constants related to assets upload

export const IMAGE_UPLOADER_SETTINGS = {
  AVATAR: {
    maxSize: 5000000,
  },
  COVER: {
    maxSize: 5000000,
    maxHeight: 2040,
    maxWidth: 2040,
    minWidth: 1200,
    minHeight: 800,
  },
  POST: {
    maxSize: 5000000,
    minHeight: 600,
    minWidth: 1200,
    maxHeight: 2000,
    maxWidth: 3500,
  },
};

// Constants related to form validation

export const HOOK_FORM_SETTINGS = {
  criteriaMode: 'all',
  mode: 'onChange',
  reValidateMode: 'onChange',
};

export const REGEX = {
  VALID_URL: /^(http:\/\/www.|https:\/\/www.|www.){1}([0-9A-Za-z-]+\.)([A-Za-z]){2,3}(\/)?/,
  CLEAN_STRINGS: /^([^0-9@&£$€‰¶()^=%!?#]*)$/i,
  EMAIL_FORMAT:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ZIP_CODE_REGEX: /^[0-9][-\d+]*(,\d+)?$/,
  FLOAT: /^[0-9][.\d+]*(,\d+)?$/,
};

export const MIN_ETH_VALUE = 0.00000001;

export const MIN_ETH_MESSAGE = `The minimum value allowed is ${MIN_ETH_VALUE.toFixed(8)}`;

export const SPREADMINT_EMAIL = 'hello@spreadmint.com';

export * from './info';
export * from './marketplace_constants';
export * from './marketplace_search_results_constants';
export * from './web3_constants';
