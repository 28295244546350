import React from 'react';

import { Icon } from '..';

const SlickArrow = (slickArrowProps) => (
  <Icon
    name={`keyboard_arrow_${slickArrowProps.direction}`}
    className={`arrow-${slickArrowProps.direction}`}
    onClick={slickArrowProps.onClick}
    size="sm"
    secondary
    transparent
    disabled={slickArrowProps.className.includes('slick-disabled')}
  />
);

export default SlickArrow;
